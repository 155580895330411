import React, { useEffect, useState, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Button, Switch } from '@blueprintjs/core';

const DEFAULT_TIME_PERIOD_IN_YEARS = 1; 

const MarketChart = ({ data, alerts }) => {
    const [series, setSeries] = useState([]);
    const [interval, setInterval] = useState('1D'); 
    const [showAlerts, setShowAlerts] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 1468);
        };

        handleResize(); 
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const formatDataForCandleStick = (data, interval) => {
            const intervals = [];

            if (data && data.dates && data.dates.length > 0) {
                const dataPoints = data.dates.map((dateStr, index) => ({
                    date: new Date(dateStr),
                    open: data.open[index],
                    high: data.high[index],
                    low: data.low[index],
                    close: data.close[index],
                }));

                dataPoints.sort((a, b) => a.date - b.date);

                const lastDate = dataPoints[dataPoints.length - 1].date;
                const timePeriodAgo = new Date(lastDate);
                timePeriodAgo.setFullYear(timePeriodAgo.getFullYear() - DEFAULT_TIME_PERIOD_IN_YEARS);

                const filteredDataPoints = dataPoints.filter(point => point.date >= timePeriodAgo);

                let groupedData = [];

                if (interval === '1D') {
                    groupedData = filteredDataPoints;
                } else if (interval === '1W') {
                    let weekStart = null;
                    let weekData = [];

                    filteredDataPoints.forEach(point => {
                        const weekNum = getWeekNumber(point.date);

                        if (weekStart === null || weekStart !== weekNum) {
                            if (weekData.length > 0) {
                                const candle = aggregateData(weekData);
                                groupedData.push(candle);
                            }
                            weekStart = weekNum;
                            weekData = [point];
                        } else {
                            weekData.push(point);
                        }
                    });
                    if (weekData.length > 0) {
                        const candle = aggregateData(weekData);
                        groupedData.push(candle);
                    }
                } else if (interval === '1M') {
                    let monthStart = null;
                    let monthData = [];

                    filteredDataPoints.forEach(point => {
                        const monthNum = point.date.getMonth();
                        const yearNum = point.date.getFullYear();

                        if (monthStart === null || monthStart.month !== monthNum || monthStart.year !== yearNum) {
                            if (monthData.length > 0) {
                                const candle = aggregateData(monthData);
                                groupedData.push(candle);
                            }
                            monthStart = { month: monthNum, year: yearNum };
                            monthData = [point];
                        } else {
                            monthData.push(point);
                        }
                    });
                    if (monthData.length > 0) {
                        const candle = aggregateData(monthData);
                        groupedData.push(candle);
                    }
                }

                intervals.push(...groupedData.map(candle => ({
                    x: candle.date,
                    y: [candle.open, candle.high, candle.low, candle.close],
                })));
            }

            return intervals;
        };

        const aggregateData = (dataPoints) => {
            const open = dataPoints[0].open;
            const close = dataPoints[dataPoints.length - 1].close;
            const high = Math.max(...dataPoints.map(p => p.high));
            const low = Math.min(...dataPoints.map(p => p.low));
            const date = dataPoints[0].date;
            return { date, open, high, low, close };
        };

        const getWeekNumber = (d) => {
            const date = new Date(d.getTime());
            date.setHours(0, 0, 0, 0);
            date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
            const week1 = new Date(date.getFullYear(), 0, 4);
            return 1 + Math.round(((date - week1) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7);
        };

        const formattedData = formatDataForCandleStick(data, interval);
        setSeries([{ data: formattedData }]);

    }, [data, interval]);

    const alertTypeLabels = useMemo(() => ({
        'above': 'Alza',
        'below': 'Baja',
    }), []);

    const annotations = useMemo(() => {
        if (showAlerts && alerts && alerts.length > 0) {
            return {
                yaxis: alerts.map(alert => {
                    const level = parseFloat(alert.level);
                    const alertType = alert.alert_type;
                    const color = alertType === 'above' ? '#54aaff' : '#ff4800';
                    const labelText = `Alerta: ${alertTypeLabels[alertType] || alertType} ${alert.level}`;
                    return {
                        y: level,
                        borderColor: color,
                        strokeDashArray: 5,
                        label: {
                            borderColor: color,
                            style: {
                                color: '#fff',
                                background: color,
                            },
                            text: labelText,
                        },
                    };
                }),
            };
        } else {
            return {
                yaxis: [],
            };
        }
    }, [showAlerts, alerts, alertTypeLabels]);

    const options = useMemo(() => ({
        chart: {
            type: 'candlestick',
            height: '100%',
            background: 'transparent',
            zoom: {
                enabled: true,
                type: 'x',
                autoScaleYaxis: true,
            },
            toolbar: {
                show: true,
                tools: {
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
                autoSelected: 'zoom',
            },
        },
        xaxis: {
            type: 'datetime',
        },
        yaxis: {
            tooltip: {
                enabled: true,
            },
        },
        theme: {
            mode: 'dark',
        },
        tooltip: {
            theme: 'dark',
        },
        grid: {
            borderColor: '#555',
        },
        dataLabels: {
            enabled: false,
        },
        annotations: annotations,
    }), [annotations]);

    const switchLabel = isSmallScreen ? 'Alertas' : 'Mostrar Alertas';

    const buttonStyle = {
        backgroundColor: 'rgb(238, 172, 24)',
        color: 'black',
        fontSize: isSmallScreen ? '8px' : '10px',
        padding: isSmallScreen ? '3px 6px' : '5px 10px',
        marginBottom: '5px',
    };

    const handleIntervalChange = (newInterval) => {
        setInterval(newInterval);
    };

    return (
        <div className='tradingview-widget-container' style={{ height: '100%', width: '100%' }}>
            <div style={{ display: 'flex', height: '80%', width: '100%' }}>
                {/* izquierda */}
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: '10px',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                }}>
                    <Switch
                        checked={showAlerts}
                        onChange={() => setShowAlerts(!showAlerts)}
                        label={switchLabel}
                        style={{
                            color: 'white',
                            fontSize: isSmallScreen ? '8px' : '10px',
                            marginBottom: '15px',
                        }}
                    />
                    <Button
                        text="D"
                        onClick={() => handleIntervalChange('1D')}
                        style={buttonStyle}
                    />
                    <Button
                        text="S"
                        onClick={() => handleIntervalChange('1W')}
                        style={buttonStyle}
                    />
                    <Button
                        text="M"
                        onClick={() => handleIntervalChange('1M')}
                        style={{
                            ...buttonStyle,
                            marginBottom: '0px',
                        }}
                    />
                </div>
                <div style={{ flex: 1 }}>
                    <ReactApexChart options={options} series={series} type="candlestick" height="110%" width="96%" />
                </div>
            </div>
        </div>
    );
};

export default MarketChart;
