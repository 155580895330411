import React, { useState, useEffect } from 'react';
import { CellText } from '../../templates/tables/Cells';

function FirstLeftRow({ data }) {
    const [paddingSize, setPaddingSize] = useState('10px'); 

    const adjustStylesBasedOnResolution = () => {
        const width = window.innerWidth;
        const height = window.innerHeight;

        if (width <= 1480 && height <= 720) {
            setPaddingSize('5px'); // Espacio vertical filas de la tabla
        } else {
            setPaddingSize('10px');  // Espacio vertical filas de la tabla
        }
    };

    useEffect(() => {
        adjustStylesBasedOnResolution();
        window.addEventListener('resize', adjustStylesBasedOnResolution);

        return () => {
            window.removeEventListener('resize', adjustStylesBasedOnResolution);
        };
    }, []);


    const calculatePercentage = (currentValue, previousValue) => {
        if (!previousValue || previousValue === 0 || !currentValue || currentValue === 0) {
            return '0.00';  
        }
        const percentage = ((currentValue - previousValue) / previousValue) * 100;
        return percentage.toFixed(2);
    };

    return (
        <div className="flex-fila" style={{ padding: `1px 20px`, textAlign: 'center', whiteSpace: 'pre-line' }}>
            <div className="flex-celda" style={{ padding: paddingSize }}>
                <CellText 
                    value={data.apertura}
                    editing={false} 
                />
            </div>
            
            {/* Celda de "Último" */}
            <div className="flex-celda" style={{ padding: paddingSize }}>
                <CellText 
                    value={(() => {
                        const spot = parseFloat(data.ultimo); 
                        const cierreAnterior = parseFloat(data.cierre_anterior); 

                        const ultimoTime = data.ultimo_time || '13:00:00';  
                        const ultimoPercentage = calculatePercentage(spot, cierreAnterior);

                        return `${spot.toFixed(2)}\n(${ultimoTime}, ${ultimoPercentage}%)`;
                    })()}
                    editing={false} 
                />
            </div>

            <div className="flex-celda" style={{ padding: paddingSize }}>
                <CellText 
                    value={data.promedio}
                    editing={false} 
                />
            </div>
            <div className="flex-celda" style={{ padding: paddingSize }}>
                <CellText 
                    value={data.maximo}
                    editing={false} 
                />
            </div>
            <div className="flex-celda" style={{ padding: paddingSize }}>
                <CellText 
                    value={data.minimo}
                    editing={false} 
                />
            </div>
            <div 
                className="flex-celda" 
                style={{ padding: paddingSize }}
                title="*El valor presentado fue suministrado por  ..." 
            >
                <CellText 
                    value={data.volumen}
                    editing={false} 
                />
            </div>
        </div>
    );
}

export default FirstLeftRow;

