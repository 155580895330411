import React, { useState, useEffect } from 'react';
import "../../templates/Templates.css";
import "../../templates/tables/Table.css";
import "../ComponentsGlobalStyle.css";
import SecondLeftRow from './SecondLeftRow';
import { useSelector } from 'react-redux';
import { formatAccordingToType } from '../../templates/Types'; 
import * as con from '../../GlobalConstants'; 

function SecondLeftTable({ data }) {
    const spot = useSelector(state => state['store']['applicationParameters']['spot']);
    
    const [fontSize, setFontSize] = useState('12px');
    const [paddingSize, setPaddingSize] = useState('5px 10px');

    const adjustStylesBasedOnResolution = () => {
        const width = window.innerWidth;

        if (width <= 768) {
            setFontSize('8px');
            setPaddingSize('3px 5px');
        } else if (width <= 1024) {
            setFontSize('10px');
            setPaddingSize('4px 8px');
        } else if (width <= 1480) {
            setFontSize('12px');
            setPaddingSize('5px 10px');
        } else {
            setFontSize('14px');
            setPaddingSize('10px 20px');
        }
    };

    useEffect(() => {
        adjustStylesBasedOnResolution();
        window.addEventListener('resize', adjustStylesBasedOnResolution);

        return () => {
            window.removeEventListener('resize', adjustStylesBasedOnResolution);
        };
    }, []);

    const safeCalculation = (fn) => {
        try {
            const result = fn();
            if (isNaN(result) || result === Infinity || result === -Infinity) {
                return '-';
            }
            return result;
        } catch (error) {
            return '-';
        }
    };

    const cierreAnterior = data.cierre_anterior;
    const primerRegistroSemana = data.primer_registro_semana;
    const apertura = data.apertura;

    const diffCierreAnterior = data.ultimo === 0 ? 0 : safeCalculation(() => (spot - cierreAnterior).toFixed(2));
    const volatilidadJornada = safeCalculation(() => spot - apertura);
    const variacionSemanal = safeCalculation(() => spot - primerRegistroSemana);
    const variacionSemanalPorcentaje = safeCalculation(() => ((spot - primerRegistroSemana) / primerRegistroSemana) * 100);

    return (
        <div className="contenedor-tabla" style={{ fontSize, padding: '0 20px' }}>
            <div className="flex-tabla">
                <div className="flex-fila header-row" style={{ padding: paddingSize }}>
                    <div className="flex-celda">CIERRE ANTERIOR</div>
                    <div className="flex-celda">DIF. CIERRE ANTERIOR</div>
                    <div className="flex-celda">TRM(HOY)</div>
                    <div className="flex-celda">VOLATILIDAD JORNADA</div>
                    <div className="flex-celda">VARIACIÓN SEMANAL</div>
                    <div className="flex-celda">INDICADOR AGOTAMIENTO</div>
                </div>
            </div>

            <div className="flex-tabla">
                <SecondLeftRow 
                    data={{
                        cierreAnterior: cierreAnterior !== null && cierreAnterior !== undefined ? cierreAnterior : '-',
                        diffCierreAnterior: diffCierreAnterior !== '-' ? diffCierreAnterior : '-',
                        trmReal: data.trm_real !== null && data.trm_real !== undefined ? formatAccordingToType(con.MONEY, data.trm_real, { allowDecimals: true }) : '-', 
                        volatilidadJornada: volatilidadJornada !== '-' ? formatAccordingToType(con.MONEY, volatilidadJornada, { allowDecimals: true }) : '-', 
                        variacionSemanal: variacionSemanal !== '-' ? variacionSemanal : '-',
                        variacionSemanalPorcentaje: variacionSemanalPorcentaje !== '-' ? variacionSemanalPorcentaje : '-',
                        indicadorAgotamiento: data.indicador_agotamiento !== null && data.indicador_agotamiento !== undefined ? data.indicador_agotamiento : '-'
                    }}
                />
            </div>
        </div>
    );
}

export default SecondLeftTable;
