import React, { useState, useEffect } from 'react';
import "../../templates/Templates.css";
import "../../templates/tables/Table.css";
import "../ComponentsGlobalStyle.css";
import { useSelector } from 'react-redux';
import FirstLeftRow from './FirstLeftRow';

function FirstLeftTable({ data }) {
    const spot = useSelector(state => state['store']['applicationParameters']['spot']); 
    const [fontSize, setFontSize] = useState('12px');
    const [paddingSize, setPaddingSize] = useState('5px 10px');

    const adjustStylesBasedOnResolution = () => {
        const width = window.innerWidth;

        if (width <= 768) {
            setFontSize('8px');
            setPaddingSize('3px 5px');
        } else if (width <= 1024) {
            setFontSize('10px');
            setPaddingSize('4px 8px');
        } else if (width <= 1480) {
            setFontSize('12px');
            setPaddingSize('5px 10px');
        } else {
            setFontSize('14px');
            setPaddingSize('10px 20px');
        }
    };

    useEffect(() => {
        adjustStylesBasedOnResolution();
        window.addEventListener('resize', adjustStylesBasedOnResolution);

        return () => {
            window.removeEventListener('resize', adjustStylesBasedOnResolution);
        };
    }, []);

    const formatNumberWithCommas = (number) => {
        if (number === null || number === undefined || isNaN(number)) return '-';
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    return (
        <div className="contenedor-tabla" style={{ fontSize, marginBottom: '20px', padding: '0 20px' }}>
            <div className="flex-tabla">
                <div className="flex-fila header-row" style={{ padding: paddingSize }}>
                    <div className="flex-celda">APERTURA</div>
                    <div className="flex-celda">ÚLTIMO</div>
                    <div className="flex-celda">PROMEDIO</div>
                    <div className="flex-celda">MAX</div>
                    <div className="flex-celda">MIN</div>
                    <div className="flex-celda">VOLUMEN</div>
                </div>
            </div>

            <div className="flex-tabla">
                <FirstLeftRow 
                    data={{
                        apertura: data.apertura === 0 ? '0' : `${formatNumberWithCommas(data.apertura)} ${data.apertura_time ? `(${data.apertura_time})` : ''}`,
                        ultimo: spot, 
                        cierre_anterior: data.cierre_anterior, 
                        promedio: data.promedio === 0 ? '0' : `${formatNumberWithCommas(data.promedio)} ${data.promedio_percentage !== null && data.promedio_percentage !== undefined ? `(${data.promedio_percentage}%)` : ''}`,
                        maximo: data.maximo === 0 ? '0' : `${formatNumberWithCommas(data.maximo)} ${data.max_time ? `(${data.max_time})` : ''}`,
                        minimo: data.minimo === 0 ? '0' : `${formatNumberWithCommas(data.minimo)} ${data.min_time ? `(${data.min_time})` : ''}`,
                        volumen: data.volumen === 0 ? '0' : `${formatNumberWithCommas(data.volumen)} ${data.trm_volumen !== null && data.trm_volumen !== undefined ? `(TRM: ${formatNumberWithCommas(data.trm_volumen)})` : ''}`
                    }}
                />
            </div>
        </div>
    );
}

export default FirstLeftTable;
