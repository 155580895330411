import React, { useEffect, useState, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { DateTime } from 'luxon';
import { Button, Switch } from '@blueprintjs/core';

const CandleStickChart = ({ data, alerts }) => {
    const [series, setSeries] = useState([]);
    const [interval, setInterval] = useState(15);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [showAlerts, setShowAlerts] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 1468); 
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const formatDataForCandleStick = (data, interval) => {
            const intervals = [];
            let currentInterval = null;

            for (let i = 0; i < data.dates.length; i++) {
                const date = DateTime.fromISO(data.dates[i]).minus({ hours: 5 }); 

                const hour = date.hour;
                if (hour >= 15 || hour < 6) {
                    continue; 
                }

                const intervalStart = Math.floor(date.toMillis() / (interval * 60 * 1000)) * (interval * 60 * 1000);

                if (!currentInterval || currentInterval.intervalStart !== intervalStart) {
                    if (currentInterval) {
                        const open = currentInterval.closes[currentInterval.closes.length - 1]; 
                        const close = currentInterval.closes[0];

                        intervals.push({
                            x: new Date(currentInterval.intervalStart),
                            y: [open, Math.max(...currentInterval.closes), Math.min(...currentInterval.closes), close],
                        });
                    }

                    currentInterval = {
                        intervalStart,
                        closes: [data.close[i]], 
                    };
                } else {
                    currentInterval.closes.push(data.close[i]);
                }
            }

            if (currentInterval) {
                const open = currentInterval.closes[currentInterval.closes.length - 1]; 
                const close = currentInterval.closes[0]; 

                intervals.push({
                    x: new Date(currentInterval.intervalStart),
                    y: [open, Math.max(...currentInterval.closes), Math.min(...currentInterval.closes), close],
                });
            }

            return intervals;
        };

        const formattedData = formatDataForCandleStick(data, interval);
        setSeries([{ data: formattedData }]);
    }, [data, interval]);

    const alertTypeLabels = useMemo(() => ({
        'above': 'Alza',
        'below': 'Baja',
    }), []);

    const annotations = useMemo(() => {
        if (showAlerts && alerts && alerts.length > 0) {
            return {
                yaxis: alerts.map(alert => {
                    const level = parseFloat(alert.level);
                    const alertType = alert.alert_type;
                    const color = alertType === 'above' ? '#54aaff' : '#ff4800'; 
                    const labelText = `Alerta: ${alertTypeLabels[alertType] || alertType} ${alert.level}`;
                    return {
                        y: level,
                        borderColor: color,
                        strokeDashArray: 5,
                        label: {
                            borderColor: color,
                            style: {
                                color: '#fff',
                                background: color,
                            },
                            text: labelText,
                        },
                        offsetX: -1500, 
                        width: '1500%', 
                    };
                }),
            };
        } else {
            return {
                yaxis: [],
            };
        }
    }, [showAlerts, alerts, alertTypeLabels]);
    

    // Opciones del gráfico
    const options = useMemo(() => ({
        chart: {
            type: 'candlestick',
            height: 200,
            background: 'transparent',
        },
        xaxis: {
            type: 'datetime',
        },
        yaxis: {
            tooltip: {
                enabled: true,
            },
        },
        theme: {
            mode: 'dark',
        },
        tooltip: {
            theme: 'dark',
        },
        grid: {
            borderColor: '#555',
        },
        dataLabels: {
            enabled: false,
        },
        annotations: annotations,
    }), [annotations]);

    if (!isSmallScreen) {
        // Pantallas grandes
        return (
            <div id="chart" style={{ marginTop: '20px', textAlign: 'center', padding: '0 20px' }}>
                <ReactApexChart options={options} series={series} type="candlestick" height={340} />
                <div style={{ 
                    marginTop: '10px', 
                    textAlign: 'center', 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center' 
                }}>
                    {/* Botones */}
                    <Button
                        text="15 Minutos"
                        onClick={() => setInterval(15)}
                        style={{
                            backgroundColor: 'rgb(238, 172, 24)',
                            color: 'black',
                            fontSize: '16px',
                            padding: '10px 20px',
                            margin: '5px',
                        }}
                    />
                    <Button
                        text="1 Hora"
                        onClick={() => setInterval(60)}
                        style={{
                            backgroundColor: 'rgb(238, 172, 24)',
                            color: 'black',
                            fontSize: '16px',
                            padding: '10px 20px',
                            margin: '5px',
                        }}
                    />
                    <Button
                        text="2 Horas"
                        onClick={() => setInterval(120)}
                        style={{
                            backgroundColor: 'rgb(238, 172, 24)',
                            color: 'black',
                            fontSize: '16px',
                            padding: '10px 20px',
                            margin: '5px',
                        }}
                    />
                    {/* Interruptor */}
                    <Switch
                        checked={showAlerts}
                        onChange={() => setShowAlerts(!showAlerts)}
                        label="Mostrar Alertas"
                        style={{ marginLeft: '20px', color: 'white', fontSize: '16px' }}
                    />
                </div>
            </div>
        );
    } else {
        // Pantallas pequeñas
        return (
            <div id="chart" style={{ display: 'flex', padding: '0 12px', marginTop: '10px' }}>
                {/* Columna izquierda */}
                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '10px' }}>
                    {/* Interruptor */}
                    <Switch
                        checked={showAlerts}
                        onChange={() => setShowAlerts(!showAlerts)}
                        label="Alertas"
                        style={{
                            color: 'white',
                            fontSize: '10px',
                            marginBottom: '15px',
                        }}
                    />
                    {/* Botones */}
                    <Button
                        text="15m"
                        onClick={() => setInterval(15)}
                        style={{
                            backgroundColor: 'rgb(238, 172, 24)',
                            color: 'black',
                            fontSize: '10px',
                            padding: '5px 10px',
                            marginBottom: '5px',
                        }}
                    />
                    <Button
                        text="1h"
                        onClick={() => setInterval(60)}
                        style={{
                            backgroundColor: 'rgb(238, 172, 24)',
                            color: 'black',
                            fontSize: '10px',
                            padding: '5px 10px',
                            marginBottom: '5px',
                        }}
                    />
                    <Button
                        text="2h"
                        onClick={() => setInterval(120)}
                        style={{
                            backgroundColor: 'rgb(238, 172, 24)',
                            color: 'black',
                            fontSize: '10px',
                            padding: '5px 10px',
                        }}
                    />
                </div>
                {/* Gráfico */}
                <ReactApexChart options={options} series={series} type="candlestick" height={200} width={1130} />
            </div>
        );
    }
};

export default CandleStickChart;
