// Reducer for all the store ORIGINAL
import * as types from "../actions/types"
import * as con from "../../GlobalConstants"
import initialParameters from "../../config/initial_parameters.json"
import { getPreviousWorkingDay, getNow, getToday, parseDate, addMonths } from "../../utils/dateFunctions"
import { generateBands, modifyTerm } from "../../utils/bandFunctions"
import { AdjustMonthlyValuesToTerm, getGeneratorScenarioFunctionByMonthlyValuesReference, getGeneratorScenarioFunctionByPercentage, getGeneratorScenarioFunctionByVolatility } from "../../utils/scenarioFunctions"
import { binaryIndexSearch, buildSurrogateUserTransactionsDict, daysIdToInt, deleteAttributeFromObject } from "../../GlobalFunctions"
import { getTotalTransactionPages } from "../../utils/transactionFunctions"
import { clearLocalAuthenticationStorage, getDefaultAuthenticationValues, getInitialAuthenticationValues } from "../../utils/authenticationFunctions"
import { setLocalStorageItem } from "../../utils/localStorageWrapper"



// Model Keys
// These are the individual store's. Most of them work isolated, but there are some cases where they
// interact.
const AUTH = con.REDUCER_AUTHENTICATION // Store for the authenticator variables of the user. Local storage variables are also included here.
const MODEL_PARAMETERS = con.REDUCER_MODEL_PARAMETERS // Store for the line's model parameters. The parameters that have to do with th main VAR calculation for the current selected line. 
const USER_LINE_APPLICATION_PARAMETERS = con.REDUCER_USER_LINE_APPLICATION_PARAMETERS // Store for the application parameters tied to the user line. Parameters regarding the customization of how information is showed on the line.
const USER_PROFILE = con.REDUCER_USER_PROFILE // Store for the user Profile Parameters. Here is included the user's image, email, selected advisors, username and other relevant information.
const USER_LINES = con.REDUCER_USER_LINES // Stores for each one of hte user line's available.
const ACCOUNTS = con.REDUCER_ACCOUNTS // Store for the current accounts in the selected user line.
const EXPOS = con.REDUCER_EXPOSURES // Store for the current exposures in the selected user line.
const FWD_COVERAGES = con.REDUCER_FWD_COVERAGES // Store for the current forward coverages in the selected user line.
const SPOT_COVERAGES = con.REDUCER_SPOT_COVERAGES // Store for the current spot coverages in the selected user line.
const OPTION_COVERAGES = con.REDUCER_OPTION_COVERAGES // Store for the current option coverages in the selected user line.
const MARKET_ALERTS = con.REDUCER_MARKET_ALERTS // Store for the current market alerts of the USDCOP in the selected user.
const ACCOUNTS_ANN = con.REDUCER_ACCOUNTS_ANNOTATIONS // Store for all the annotations of the current accounts in the selected user line.
const EXPOS_ANN = con.REDUCER_EXPOSURES_ANNOTATIONS // Store for all the annotations of the current exposures in the selected user line.
const FWD_COVERAGES_ANN = con.REDUCER_FWD_COVERAGES_ANNOTATIONS // Store for all the annotations of the current forward coverages in the selected user line.
const SPOT_COVERAGES_ANN = con.REDUCER_SPOT_COVERAGES_ANNOTATIONS // Store for all the annotations of the current spot coverages in the selected user line.
const OPTION_COVERAGES_ANN = con.REDUCER_OPTION_COVERAGES_ANNOTATIONS // Store for all the annotations of the current option coverages in the selected user line.
const SCENARIOS = con.REDUCER_SCENARIOS // Store for all the available scenarios
const APPLICATION_PARAMETERS = con.REDUCER_APPLICATION_PARAMETERS // Store for the application parameters. Here are all the variables regarding usability and user interaction (not model computation)
const ERRORS = con.REDUCER_ERRORS // Store that gathers all the generated errors
const SURROGATE_USERS_TRANSACTIONS = con.REDUCER_SURROGATE_USERS_TRANSACTIONS // Store that lads all the regular user transactions to enable the staff overview (surrogate mode)  
const ADVISORS = con.REDUCER_ADVISORS // Store with the available advisors 
const ADMIN_LINE_COMMENTS = con.REDUCER_ADMIN_LINE_COMMENTS // Store for the different comments made on the admin panel
const MARKET_VALUES = con.REDUCER_MARKET_VALUES // Store for all market values (TRM, IBR, Volatility etc..)

// Static initial values
const INITIAL_TRANS_SUMMARY = {}
con.TRANSACTIONS_SUMMARY_COLUMNS.forEach((col) => INITIAL_TRANS_SUMMARY[col] = 0)

// Default user profile
const DEFAULT_USER_PROFILE = {[con.AVATAR_IMAGE] : null, [con.ADVISORS] : []}

// // Default Curves
// const DEFAULT_SINGLE_CURVE = {[con.DAYS] : con.FORWARD_CURVE_DAYS.map(daysIdToInt),
//                                 [con.PERCENTAGES] : con.FORWARD_CURVE_DAYS.map(_ => 0.08)}

const DEFAULT_DOUBLE_SINGLE_CURVE = {[con.DAYS] : con.FORWARD_CURVE_DAYS.map(daysIdToInt),
                                    [con.BUY] : con.FORWARD_CURVE_DAYS.map(_ => 0.08),
                                    [con.SELL] : con.FORWARD_CURVE_DAYS.map(_ => 0.07)}

// Initial State
const initialState = {
    
    // Default states
    [AUTH] : getInitialAuthenticationValues(), // Loads from local storage if available
    [USER_PROFILE] : {...DEFAULT_USER_PROFILE}, // Loads the default user profile 
    [USER_LINES] : [], // No default user lines
    [MODEL_PARAMETERS] : {...initialParameters[con.DEFAULT_MODEL_PARAMETERS], [con.CURRENT_DATE] : getToday()},  // Loads initial model parameters from config and set to today
    [USER_LINE_APPLICATION_PARAMETERS] : {...initialParameters[con.DEFAULT_USER_LINE_APPLICATION_PARAMETERS]}, // Loads initial line application parameters from config
    [APPLICATION_PARAMETERS] : {[con.APP_READY]: false, // Boolean indicating that the application has loaded the necessary initial information from server and is ready to use.
                                [con.IS_MOBILE] : true, // Boolean indicating if the screen is small enough to be considered mobile                            
                                [con.IS_MOBILE_ENABLED] : true, // Boolean indicating if mobile pages are enabled  OCULTAR RESPONSIVE PONER false 
                                [con.SERVER] : {[con.STATUS] : con.NOT_STARTED}, // Status object for the server state.  
                                [con.IS_OFFLINE] : false, // Legacy: Boolean indicating that there is no connection to the server and that the application is offline. Currently there is no support for offline mode.                                 
                                [con.LOGGED_IN] : {[con.STATUS] : con.NOT_STARTED}, // Status object for the login process 
                                [con.SELECTED_LINE] : null, // Currently selected line
                                [con.SELECTED_SCENARIO] : con.SCENARIO_VAR_UP, // Currently selected scenario
                                [con.CHECK_PASSWORD] : {[con.STATUS] : con.NOT_STARTED}, // Status object for checking the user's password
                                [con.CHANGE_PASSWORD] : {[con.STATUS] : con.NOT_STARTED}, // Status object for the password change process                                                                  
                                [con.HAS_TRANSACTIONS]: false, // Boolean indicating if the user has enough type of active transactions to show the main results
                                [con.INTERNAL_TRANSACTION_CHANGE] : 0, // Parameter checking the when the user changed attributes of a transactions. It is used for usability and smoother transitions in the interface.
                                [con.SPOT] : initialParameters[con.DEFAULT_SPOT],  // Application's SPOT
                                [con.CURRENT_DATE] : getToday(), // Application's current date
                                [con.LAST_SPOT_UPDATE] : initialParameters[con.LAST_SPOT_UPDATE], // Parameter for tracking last SPOT update. It is used to determined if SPOT needs to be updated
                                [con.SPOT_DIRECTION] : con.NEUTRAL, // Parameter indicating the direction of change in the SOOT. It is used to determine the SPOT's update color.
                                [con.SPOT_SCHEME] : con.AUTOMATIC, // Selected global SPOT scheme (Manual or Automatic)                                                                
                                [con.SPOT_SEARCH_INTERVAL] : null, //  Parameter that stores the Automatic SPOT interval search JS Object                                                      
                                // Market
                                // ----------
                                [con.SELECTED_MARKET_ASSET] : con.COP_ASSET[con.ID], // Currently selected asset (for market visualization charts)
                                // Calculator
                                [con.FORWARD_CALCULATOR_FORWARD_CURVE] :  DEFAULT_DOUBLE_SINGLE_CURVE, // Forward Curve for the Calculator 
                                // Forward Assessment
                                [con.FORWARD_ASSESSMENT_REFERENCE_DATE] : getPreviousWorkingDay(), // Forward Assessment reference Date 
                                [con.FORWARD_ASSESSMENT_START_DATE] : addMonths(getPreviousWorkingDay(), -3, true), // Forward Assessment Start Date
                                [con.FORWARD_ASSESSMENT_REFERENCE_TRM] : initialParameters[con.DEFAULT_SPOT], // Forward Assessment reference TRM value
                                [con.FORWARD_ASSESSMENT_REFERENCE_NEXT_DAY_TRM] : initialParameters[con.DEFAULT_SPOT], // Forward Assessment reference Next day TRM value
                                [con.FORWARD_ASSESSMENT_REFERENCE_RATE_CLOSE] : initialParameters[con.DEFAULT_SPOT], // Forward Assessment reference close rate
                                [con.FORWARD_ASSESSMENT_RATE] : initialParameters[con.DEFAULT_SPOT], // Forward Assessment Used Rate
                                [con.FORWARD_ASSESSMENT_RATE_ORIGIN] : con.SPOT, // Forward Assessment Rate type (TRM, Spot, Close, etc..)
                                [con.FORWARD_ASSESSMENT_INCLUDE_PURCHASES] : true, // Forward Assessment Include purchases by default
                                [con.FORWARD_ASSESSMENT_INCLUDE_SALES] : true, // Forward Assessment Include sales by default
                                [con.FORWARD_ASSESSMENT_SHOW_HIDDEN_COLUMNS] : false, // Show the hidden values in the Forward Assessment page 
                                [con.FORWARD_ASSESSMENT_SHOW_EXPIRED_COVERAGES] : false, // Show the expired forward coverages in the Forward Assessment page 
                                [con.FORWARD_ASSESSMENT_REPORT] : {[con.REPORT_FILE_NAME] : null, // Parameters for report the Forward Assessment report generation 
                                                                    [con.GENERATING] : false},  
                                // Option Assessment
                                [con.OPTION_ASSESSMENT_REFERENCE_DATE] : getPreviousWorkingDay(),
                                [con.OPTION_ASSESSMENT_START_DATE] : addMonths(getPreviousWorkingDay(), -3, true), // Option Assessment Start Date
                                [con.OPTION_ASSESSMENT_REFERENCE_TRM] : initialParameters[con.DEFAULT_SPOT], // Option Assessment reference TRM value
                                [con.OPTION_ASSESSMENT_REFERENCE_NEXT_DAY_TRM] : initialParameters[con.DEFAULT_SPOT], // Option Assessment reference Next day TRM value
                                [con.OPTION_ASSESSMENT_REFERENCE_RATE_CLOSE] : initialParameters[con.DEFAULT_SPOT], // Option Assessment reference close rate
                                [con.OPTION_ASSESSMENT_RATE] : initialParameters[con.DEFAULT_SPOT], // Option Assessment Used Rate
                                [con.OPTION_ASSESSMENT_RATE_ORIGIN] : con.SPOT, // Option Assessment Rate type (TRM, Spot, Close, etc..) 
                                [con.OPTION_ASSESSMENT_INCLUDE_PURCHASES] : true, // Option Assessment Include purchases by default
                                [con.OPTION_ASSESSMENT_INCLUDE_SALES] : true, // Option Assessment Include sales by default
                                [con.OPTION_ASSESSMENT_INCLUDE_CALL] : true, // Option Assessment Include calls by default
                                [con.OPTION_ASSESSMENT_INCLUDE_PUT] : true, // Option Assessment Include puts by default
                                [con.OPTION_ASSESSMENT_SHOW_HIDDEN_COLUMNS] : false, // Show the hidden values in the Option Assessment page 
                                [con.OPTION_ASSESSMENT_SHOW_EXPIRED_COVERAGES] : false, // Show the expired Option coverages in the Forward Assessment page 
                                [con.OPTION_ASSESSMENT_REPORT] : {[con.REPORT_FILE_NAME] : null, // Parameters for report the Option Assessment report generation 
                                                                    [con.GENERATING] : false},                                                     
                                // Transactions
                                // ----------
                                [con.SEARCH_FUN] : con.DEFAULT_SEARCH_FUNCTION, // Transactions search function
                                [con.LOADING_CURRENT_TRANSACTIONS] : false, // Parameter indicting if the interface is loading the current transactions (used for smoother transitions)
                                [con.VISUALIZATION_PERIODICITY] : con.PERIODICITY_MONTH, // Results visualization periodicity
                                [con.ONLY_NON_ZERO_ROWS] : true, // Boolean indicating if only rows with non zero amount should be shown
                                [con.TRANSACTION_PREPAYED] : {[con.STATUS] : con.NOT_STARTED}, // Status object for prepaying an exposure through the server
                                [con.UPLOADED_FILE] : null, // Variable to store the uploaded transactions file
                                [con.UPLOADING_FILE] : false, // Variable to track if the user is currently uploading the transactions file.
                                [con.ADDING_NEW_ROW] : false, // Variable indicating if the use is adding a new row.
                                [con.CAN_ADD_TRANSACTION] : true, // Variable indicating if the transaction type (Exposure, FWD Coverage, Account etc...) allows adding new rows.
                                [con.CAN_DELETE_ALL_TRANSACTIONS] : true, // Variable indicating if the user has permission to delete all transactions.
                                [con.INCLUDE_ACTIVE]: true, // Variable to track option to show or hide active transactions.
                                [con.INCLUDE_EXPIRED] : true, // Variable to track option to show or hide expired transactions.
                                [con.INCLUDE_PREPAY] : true, // Variable to track option to show or hide pre payed transactions.
                                [con.INCLUDE_PURCHASES] : true, // Variable to track option to show or hide purchases transactions.
                                [con.INCLUDE_SALES] : true, // Variable to track option to show or hide sales transactions.
                                [con.FILTER_FUN] : (ob) => true, // Transactions's final filter function for row displaying
                                [con.FWD_ASSESSMENT_FILTER_FUN] : generate_forward_assessment_filter_fun({
                                    [con.FORWARD_ASSESSMENT_REFERENCE_DATE] : getPreviousWorkingDay(),
                                    [con.FORWARD_ASSESSMENT_SHOW_EXPIRED_COVERAGES] : false,
                                    [con.FORWARD_ASSESSMENT_START_DATE] : addMonths(getPreviousWorkingDay(), -3, true),
                                    [con.FORWARD_ASSESSMENT_INCLUDE_PURCHASES] : true,
                                    [con.FORWARD_ASSESSMENT_INCLUDE_SALES] : true}), // FWD's final filter function for assessment
                                [con.OPTION_ASSESSMENT_FILTER_FUN] : generate_option_assessment_filter_fun({
                                    [con.OPTION_ASSESSMENT_REFERENCE_DATE] : getPreviousWorkingDay(),
                                    [con.OPTION_ASSESSMENT_SHOW_EXPIRED_COVERAGES] : false,
                                    [con.OPTION_ASSESSMENT_START_DATE] : addMonths(getPreviousWorkingDay(), -3, true),
                                    [con.OPTION_ASSESSMENT_INCLUDE_PURCHASES] : true,
                                    [con.OPTION_ASSESSMENT_INCLUDE_SALES] : true,
                                    [con.OPTION_ASSESSMENT_INCLUDE_CALL] : true,
                                    [con.OPTION_ASSESSMENT_INCLUDE_PUT] : true}), // Options' final filter function for assessment
                                [con.ADD_SPOT_COVERAGE_TO_COMPENSATION_ACCOUNT]: false, // Variable indicating whether a spot coverage is going to the compensation account (this implies writing into the DB)
                                [con.SELECTED_TRANSACTIONS] : con.EXPOSURES, // Currently displayed transactions 
                                [con.CURRENT_TRANSACTION_NAVIGATION_PAGE] : // Object for handling navigation pages when the user has too many transactions
                                                        {[con.EXPOSURES] : 0, 
                                                         [con.COVERAGES_FWD] : 0,
                                                         [con.COVERAGES_SPOT] :0,
                                                         [con.COVERAGES_OPTION] :0,
                                                         [con.ACCOUNTS] : 0},
                                [con.TOTAL_TRANSACTIONS_DISPLAYED] : { // Object for handling the total number of transactions the user has
                                                                    [con.EXPOSURES] : 0,
                                                                    [con.COVERAGES_FWD] : 0,
                                                                    [con.COVERAGES_SPOT] :0,
                                                                    [con.COVERAGES_OPTION] :0,
                                                                    [con.ACCOUNTS] : 0},                                
                                [con.TRANSACTIONS_INSERTED] : { //Object to track insertion status of the transactions (from a user's uploaded file)
                                                               [con.EXPOSURES] : {[con.STATUS] : con.OK},
                                                               [con.COVERAGES_FWD] : {[con.STATUS] : con.OK},
                                                               [con.COVERAGES_SPOT] : {[con.STATUS] : con.OK},
                                                               [con.COVERAGES_OPTION] :{[con.STATUS] : con.OK},
                                                               [con.ACCOUNTS] : {[con.STATUS] : con.OK}
                                                            },   
                                [con.TRANSACTIONS_LOADED] : { // Object to track the loading status of the transactions from the server 
                                                            [con.EXPOSURES] : {[con.STATUS] : con.NOT_STARTED},
                                                            [con.COVERAGES_FWD] : {[con.STATUS] : con.NOT_STARTED},
                                                            [con.COVERAGES_SPOT] : {[con.STATUS] : con.NOT_STARTED},
                                                            [con.COVERAGES_OPTION] : {[con.STATUS] : con.NOT_STARTED}, 
                                                            [con.ACCOUNTS] : {[con.STATUS] : con.NOT_STARTED}                                                            
                                                         },                             
                                [con.SURROGATE_TRANSACTIONS_LOADED] : // Object to track the loading status of the surrogate user's transactions from the server   
                                                        {[con.EXPOSURES] : {[con.STATUS] : con.NOT_STARTED},
                                                            [con.COVERAGES_FWD] : {[con.STATUS] : con.NOT_STARTED},
                                                            [con.COVERAGES_SPOT] : {[con.STATUS] : con.NOT_STARTED},
                                                            [con.COVERAGES_OPTION] : {[con.STATUS] : con.NOT_STARTED},
                                                            [con.ACCOUNTS] : {[con.STATUS] : con.NOT_STARTED},
                                                            [con.MODEL_PARAMETERS] : {[con.STATUS] : con.NOT_STARTED}
                                                         },                                 
                                // Model Parameters
                                // ------------------
                                [con.MODEL_PARAMETERS_SAVED] : {[con.STATUS] : con.NOT_STARTED}, // Stats object for saving the model's parameters
                                [con.MODEL_PARAMETERS_FETCHED] : {[con.STATUS] : con.NOT_STARTED}, // Status object for fetching the model's parameters
                                [con.CARRY_OVER_COVERAGE] : false, // Variable indicating if the over coverages should be carried to the next period
                                // User Line Application Parameters
                                // ------------------
                                [con.USER_LINE_APPLICATION_PARAMETERS_SAVED] : {[con.STATUS] : con.NOT_STARTED}, // Status Object for saving the application parameters
                                [con.USER_LINE_APPLICATION_PARAMETERS_FETCHED] : {[con.STATUS] : con.NOT_STARTED},  // Status Objet for fetching the application parameters
                                // User Information Saved
                                [con.USER_INFORMATION_SAVED] : {[con.STATUS] : con.NOT_STARTED}, // Status object for saving the user's information  
                                // Lines
                                [con.USER_LINE_SAVED] : {[con.STATUS] : con.NOT_STARTED}, // Status Object for saving the user's line 
                                [con.USER_LINE_DELETED] : {[con.STATUS] : con.NOT_STARTED}, // Status Object for deleting a user's line
                                [con.USER_LINE_ADDED] : {[con.STATUS] : con.NOT_STARTED},  // Status Object for adding a user line
                                [con.USER_LINE_COMMENT_ADDED] : {[con.STATUS] : con.NOT_STARTED}, // Status Object for commenting on a user's line                                
                                // All Transactions
                                [con.ALL_TRANSACTIONS_LOADED] : false, // Boolean indicating if all the user's transactions have loaded (for visualization transitions)
                                [con.ALL_SURROGATE_TRANSACTIONS_LOADED] : false, // Boolean indicating if all the surrogate transactions have loaded (for visualization in the admin panel)
                                [con.SELECTED_BAND] : con.LOWER_BAND, // Selected band for visualization
                                [con.SELECTED_VAR] : con.VAR, // Selected VaR origin (Opportunistic, High, middle or lower band)
                                [con.PURCHASE_REPORT] : {[con.REPORT_FILE_NAME] : null, // Object for the purchase report generation tracking
                                                         [con.GENERATING] : false},
                                [con.POSITION_REPORT] : {[con.REPORT_FILE_NAME] : null, // Object for the position report generation tracking
                                                            [con.GENERATING] : false},
                                [con.COMMITTEE_REPORT] : {[con.REPORT_FILE_NAME] : null, // Object for the position report generation tracking
                                                            [con.GENERATING] : false},
                                [con.SURROGATE_USER_DICT] : {},// All surrogate available user profiles
                                // Advisor
                                [con.ADVISOR_SAVED] : {[con.STATUS] : con.NOT_STARTED}, // Status object for saving an advisor
                                [con.MAIN_ADVISOR_SAVED] : {[con.STATUS] : con.NOT_STARTED}, // Status object for saving the main advisor                                                              
                                // Orion and Coverages Grid
                                [con.ORION_SUMMARY_FETCHED] : {[con.STATUS] : con.NOT_STARTED}, // Status object for fetching the orion's summary
                                [con.ORION_BETAS_FETCHED] : {[con.STATUS] : con.NOT_STARTED}, // Status object for fetching the orion's betas                          
                                [con.COVERAGES_GRID_CLIENT_TYPE] : initialParameters[con.DEFAULT_MODEL_PARAMETERS][con.CLIENT_TYPE], // Parameter to change  the client's type in the coverages grid panel
                                [con.COVERAGES_GRID_STOP_LOSS] : initialParameters[con.DEFAULT_COVERAGE_GRID_PARAMETERS][con.COVERAGES_GRID_STOP_LOSS], // Coverages Grid Stop Loss 
                                [con.COVERAGES_GRID_INITIAL_LEVEL] : initialParameters[con.DEFAULT_COVERAGE_GRID_PARAMETERS][con.COVERAGES_GRID_INITIAL_LEVEL], // Coverages Grid Initial Level
                                [con.COVERAGES_GRID_FINAL_LEVEL] : initialParameters[con.DEFAULT_COVERAGE_GRID_PARAMETERS][con.COVERAGES_GRID_FINAL_LEVEL], // Coverages Grid Final Level 
                                [con.COVERAGES_GRID_SPREAD] : initialParameters[con.DEFAULT_COVERAGE_GRID_PARAMETERS][con.COVERAGES_GRID_SPREAD],// Coverages Grid Spread
                                [con.COVERAGES_GRID_DESIRED_BAND] : initialParameters[con.DEFAULT_COVERAGE_GRID_PARAMETERS][con.COVERAGES_GRID_DESIRED_BAND], // Coverages Grid Desired Band
                                [con.COVERAGES_GRID_AMOUNT_TO_BUY] : initialParameters[con.DEFAULT_COVERAGE_GRID_PARAMETERS][con.COVERAGES_GRID_AMOUNT_TO_BUY], // Coverages Grid Amount to Buy
                                // Periodicity Parameters
                                [con.TERM_IN_MONTHS] : initialParameters[con.DEFAULT_MODEL_PARAMETERS][con.TERM_PERIODICITY]
                            },
    // Market Values
    // ------------
    [MARKET_VALUES] : { [con.HISTORIC_DOLLAR_INFO] : {[con.DATES] : [], [con.TRM] : [], [con.CLOSE] : []}, // ALl historic Dollar information
                        [con.MARKET_SUMMARIES] : { // The different market summaries (volatility and average per time window)
                                                [con.MARKET_10_DAYS] : { [con.VOLATILITY] : 0.1, [con.AVERAGE] : 4800},
                                                [con.MARKET_30_DAYS] : { [con.VOLATILITY] : 0.1, [con.AVERAGE] : 4800},
                                                [con.MARKET_90_DAYS] : { [con.VOLATILITY] : 0.1, [con.AVERAGE] : 4800},
                                                [con.MARKET_180_DAYS] : { [con.VOLATILITY] : 0.1, [con.AVERAGE] : 4800},
                                                [con.MARKET_360_DAYS] : { [con.VOLATILITY] : 0.1, [con.AVERAGE] : 4800},
                                                [con.MARKET_TOTAL_DAYS] : { [con.VOLATILITY] : 0.1, [con.AVERAGE] : 4800}
                                                 },
                        [con.MARKET_ASSETS] : {[con.COP_ASSET[con.ID]] : {...con.COP_ASSET}}, // All the registered market assets
                        [con.ALL_FORWARD_CURVES] : { // The forward curves by date
                            [con.DATES] : [],
                            [con.VALUES] : []                           
                        },
                        [con.MARKET_FORWARD_CURVE] : {}, // Current (latest) market curve
                        [con.ALL_IBR] : {
                            [con.DATES] : [],
                            [con.VALUES] : []                           
                        },
                        [con.ALL_SOFR_CURVES] : {
                            [con.DATES] : [],
                            [con.VALUES] : []                           
                        },
                        [con.VOLATILITY_SURFACE] : [],
                        [con.MARKET_IBR] : {}, // Current (latest) market IBR
                        [con.ORION_SUMMARY] : null, // Current orion's summary
                        [con.ORION_BETAS] : null // Current orion's etas
    },
    // Transactions
    // ---------------
    [ACCOUNTS] : {}, // Accounts
    [EXPOS] : {}, // Exposures
    [FWD_COVERAGES] : {}, // Forward Coverages
    [SPOT_COVERAGES] : {}, // Spot Coverages
    [OPTION_COVERAGES] : {}, // Option Coverages
    [MARKET_ALERTS] : {}, // Option Coverages

    // Annotation
    // -----------
    [ACCOUNTS_ANN] : {}, // Accounts Annotation
    [EXPOS_ANN] : {}, // Exposures Annotations
    [FWD_COVERAGES_ANN] : {}, // Forward Coverages Annotations
    [SPOT_COVERAGES_ANN] : {},  // Spot Coverages Annotations
    [OPTION_COVERAGES_ANN] : {},  // Option Coverages Annotations
    // Scenarios
    // ----------
    [SCENARIOS] : { [con.SCENARIO_VAR_UP] : {[con.ID] : con.SCENARIO_VAR_UP, name : con.SCENARIO_VAR_UP_NAME, [con.GENERATING_FUNCTION] : getGeneratorScenarioFunctionByVolatility(initialParameters[con.DEFAULT_MODEL_PARAMETERS][con.MARKET_VOLATILITY],
                                                                                                                                                        initialParameters[con.DEFAULT_MODEL_PARAMETERS][con.ESC_CONFIDENCE_LEVEL],
                                                                                                                                                        1)},
                    [con.SCENARIO_VAR_DOWN] : {[con.ID] : con.SCENARIO_VAR_DOWN, name : con.SCENARIO_VAR_DOWN_NAME, [con.GENERATING_FUNCTION] : getGeneratorScenarioFunctionByVolatility(initialParameters[con.DEFAULT_MODEL_PARAMETERS][con.MARKET_VOLATILITY],
                                                                                                                                                            initialParameters[con.DEFAULT_MODEL_PARAMETERS][con.ESC_CONFIDENCE_LEVEL],
                                                                                                                                                            -1)},                                                                                                                                                        
                    [con.SCENARIO_PERCENTAGE] : {[con.ID] : con.SCENARIO_PERCENTAGE, name: con.SCENARIO_PERCENTAGE_NAME, [con.GENERATING_FUNCTION] : getGeneratorScenarioFunctionByPercentage(initialParameters[con.DEFAULT_MODEL_PARAMETERS][con.ESC_PERCENTUAL_CHANGE],
                                                                                                                                                                      initialParameters[con.DEFAULT_MODEL_PARAMETERS][con.CLIENT_TYPE])},
                    // Default
                    [con.SCENARIO_VINCO] : {[con.ID] : con.SCENARIO_VINCO, name : con.SCENARIO_VINCO_NAME, [con.GENERATING_FUNCTION] : getGeneratorScenarioFunctionByMonthlyValuesReference(con.DEFAULT_VINCO_SCENARIO_VALUES,false, true),[con.MONTHLY_VALUES] :  con.DEFAULT_VINCO_SCENARIO_VALUES}},
    // Errors
    // ---------
    [ERRORS] : {[con.LATEST_ERROR] : null,
                [con.SESSION_ERRORS] : []},
    // Surrogate Users Transactions
    // ----------------------------
    [SURROGATE_USERS_TRANSACTIONS] : {},
    // Advisors
    // --------
    [ADVISORS] : [],
    // Admin Panel Line Comments
    [ADMIN_LINE_COMMENTS] : [],
    
}



/**
 * Main actions function.
 * The structure of the function is a main switch with a final clean-up section at the end
 * @param {object} state - Current state of the store   
 * @param {object} action  - Object containing the action and the payload 
 * @returns The new state
 */
export default function setStateFun(state = initialState, action)
{

    // Global variables
    // Variables used in several scenarios (are declared here to use correctly the Switch notation)
    let newOb = null
    let annotation = null   
    let transactionType = null
    let transactionArray = null
    let currentParameters = null
    let currentMarketValues = null
    let currentAppParameters = null
    let currentModelParameters = null
    let selectedReducer = null
    let selectedAnnotations = null
    let scenarios = null
    let selectedLine = null
    let selectedLineIndex = -1
    let lines = null
    let custom_scenario_values = null
    let newPage = null
    let surrogateUser = null
    let advisors = []    
    let mainAdvisorId = null
    let index = -1
    let temp = null
    

    switch(action.type){

        // Authentication
        // --------------
        // Authentication actions (login, logout, etc...)
        
        case types.LOGIN:
            // User login case

            setLocalStorageItem(con.LOCAL_TOKEN, action.payload[con.TOKEN])
            setLocalStorageItem(con.LOCAL_USER, action.payload[con.USER], con.OBJECT)
            setLocalStorageItem(con.LOCAL_LAST_TOKEN_REFRESHED_TIMESTAMP, getNow())

            state = {
                ...state,
                [AUTH] : {
                    ...state[AUTH],
                    [con.IS_AUTHENTICATED] : true,                
                    ...action.payload
                }
                }
                break   
        case types.REFRESH_TOKEN:
            // Action that refreshes the user's token before it expires so they can continue using the application

            setLocalStorageItem(con.LOCAL_TOKEN, action.payload[con.TOKEN])
            setLocalStorageItem(con.LOCAL_LAST_TOKEN_REFRESHED_TIMESTAMP, getNow())


            state = {
                ...state,
                [AUTH] : {
                    ...state[AUTH],
                    [con.TOKEN] : action.payload[con.TOKEN],                
                }
                }
            
                break
        
        case types.LOGOUT_FOR_INACTIVITY:
            // Action that logs out due to inactivity from the user. Activity means mouse clicks

            clearLocalAuthenticationStorage()

            
            state = {...initialState,
                    [SCENARIOS] : state[SCENARIOS],
                    [AUTH] : {...getDefaultAuthenticationValues(),[con.NEEDS_TO_LOGIN_AGAIN] : true},
                    [APPLICATION_PARAMETERS]: {...getLoggedOutInitialApplicationParameters()}}
            break

        case types.USER_LOGOUT:
            // Action that logs out at the user's request

            clearLocalAuthenticationStorage()

            
            state = {...initialState,
                    [SCENARIOS] : state[SCENARIOS],
                    [AUTH] : {...getDefaultAuthenticationValues()},
                    [APPLICATION_PARAMETERS]: {...getLoggedOutInitialApplicationParameters()}}
            break

        case types.SET_SURROGATE_USER:
            // Actions that sets another user as surrogate user. This is only available to admin level users
            // and allows them to manipulate the application exactly as the surrogate user would.

            surrogateUser = action.payload
            
            lines = []                                            
                            
            state = {
                ...state,
                [AUTH] : { ...state[AUTH], [con.SURROGATE_USER]: surrogateUser},
                [APPLICATION_PARAMETERS] : {...state[APPLICATION_PARAMETERS], [con.SELECTED_LINE] : selectedLine},
                [USER_LINE_APPLICATION_PARAMETERS] : {...initialParameters[con.DEFAULT_USER_LINE_APPLICATION_PARAMETERS]},
                [USER_LINES] : [...lines]}

            break   

        // Application Parameters
        // ------------------------
        // Different actions for the application parameters
        case types.SET_APPLICATION_PARAMETER:
            // Sets a specific application parameter. If that change has ripple effects they should be included here.

            currentAppParameters = state[APPLICATION_PARAMETERS]
            
            // Extracts and Updates
            let parameterId = action.payload[con.ID]
            currentAppParameters[parameterId] = action.payload[con.VALUE]
            

            // Parameters that alter other parameters
            switch (parameterId) {
                
                case con.TOTAL_TRANSACTIONS_DISPLAYED:
                    // Updates the current navigation page if the total transactions changes
                    [con.EXPOSURES, con.COVERAGES_SPOT, con.COVERAGES_FWD, con.COVERAGES_OPTION, con.ACCOUNTS].forEach((col) => 
                    currentAppParameters[con.CURRENT_TRANSACTION_NAVIGATION_PAGE][col] = Math.min(currentAppParameters[con.CURRENT_TRANSACTION_NAVIGATION_PAGE][col], 
                                                    getTotalTransactionPages(action.payload[con.VALUE][col])))
                    break;
                case con.SURROGATE_USER_DICT:
                    // Updates the surrogate transactions if the structure of the surrogate dictionary changes
                    state[SURROGATE_USERS_TRANSACTIONS] = buildSurrogateUserTransactionsDict(action.payload[con.VALUE])                    
                    break;

                case con.FORWARD_ASSESSMENT_REFERENCE_DATE:
                    // Updates the different rates displayed in the assessment page if the reference sate changes

                    // Updates the Reference TRM
                    if(state[MARKET_VALUES][con.HISTORIC_DOLLAR_INFO][con.DATES].length > 0)
                    {
                        // Checks closest match
                        index = binaryIndexSearch(currentAppParameters[con.FORWARD_ASSESSMENT_REFERENCE_DATE],
                                                    state[MARKET_VALUES][con.HISTORIC_DOLLAR_INFO][con.DATES],
                                                    false)

                        currentAppParameters[con.FORWARD_ASSESSMENT_REFERENCE_TRM] = state[MARKET_VALUES][con.HISTORIC_DOLLAR_INFO][con.TRM][index]
                        currentAppParameters[con.FORWARD_ASSESSMENT_REFERENCE_NEXT_DAY_TRM] = 
                        state[MARKET_VALUES][con.HISTORIC_DOLLAR_INFO][con.TRM][index + 1] ?? 
                        state[MARKET_VALUES][con.HISTORIC_DOLLAR_INFO][con.TRM][index];

                        currentAppParameters[con.FORWARD_ASSESSMENT_REFERENCE_RATE_CLOSE] = state[MARKET_VALUES][con.HISTORIC_DOLLAR_INFO][con.CLOSE][index]

                    }
                    currentAppParameters[con.FWD_ASSESSMENT_FILTER_FUN] = generate_forward_assessment_filter_fun(currentAppParameters)
                    break;

                    case con.FORWARD_ASSESSMENT_SHOW_EXPIRED_COVERAGES:
                    case con.FORWARD_ASSESSMENT_START_DATE:
                    case con.FORWARD_ASSESSMENT_INCLUDE_SALES:
                    case con.FORWARD_ASSESSMENT_INCLUDE_PURCHASES:
                        currentAppParameters[con.FWD_ASSESSMENT_FILTER_FUN] = generate_forward_assessment_filter_fun(currentAppParameters)
                        break;

                    case con.OPTION_ASSESSMENT_REFERENCE_DATE:
                        // Updates the different rates displayed in the assessment page if the reference sate changes
    
                        // Updates the Reference TRM
                        if(state[MARKET_VALUES][con.HISTORIC_DOLLAR_INFO][con.DATES].length > 0)
                        {
                            // Checks closest match
                            index = binaryIndexSearch(currentAppParameters[con.OPTION_ASSESSMENT_REFERENCE_DATE],
                                                        state[MARKET_VALUES][con.HISTORIC_DOLLAR_INFO][con.DATES],
                                                        false)
    
                            currentAppParameters[con.OPTION_ASSESSMENT_REFERENCE_TRM] = state[MARKET_VALUES][con.HISTORIC_DOLLAR_INFO][con.TRM][index]
                            currentAppParameters[con.OPTION_ASSESSMENT_REFERENCE_NEXT_DAY_TRM] = 
                            state[MARKET_VALUES][con.HISTORIC_DOLLAR_INFO][con.TRM][index + 1] ?? 
                            state[MARKET_VALUES][con.HISTORIC_DOLLAR_INFO][con.TRM][index];
    
                            currentAppParameters[con.OPTION_ASSESSMENT_REFERENCE_RATE_CLOSE] = state[MARKET_VALUES][con.HISTORIC_DOLLAR_INFO][con.CLOSE][index]
    
                        }
                        currentAppParameters[con.OPTION_ASSESSMENT_FILTER_FUN] = generate_option_assessment_filter_fun(currentAppParameters)
                        break;

                    case con.OPTION_ASSESSMENT_SHOW_EXPIRED_COVERAGES:
                    case con.OPTION_ASSESSMENT_START_DATE:
                    case con.OPTION_ASSESSMENT_INCLUDE_SALES:
                    case con.OPTION_ASSESSMENT_INCLUDE_PURCHASES:
                    case con.OPTION_ASSESSMENT_INCLUDE_CALL:
                    case con.OPTION_ASSESSMENT_INCLUDE_PUT:
                        currentAppParameters[con.OPTION_ASSESSMENT_FILTER_FUN] = generate_option_assessment_filter_fun(currentAppParameters)
                        break;

                default:
                    break;
            }
            
            state = {
                ...state,
                [APPLICATION_PARAMETERS] : { ...state[APPLICATION_PARAMETERS], ...currentAppParameters}
                }
            
            break

        case types.SET_LOADING_PARAMETER:
            // Action that sets a specific transaction loading parameter 


            state = {...state,
                    [APPLICATION_PARAMETERS] : {...state[APPLICATION_PARAMETERS],
                                                [con.TRANSACTIONS_LOADED] : {...state[APPLICATION_PARAMETERS][con.TRANSACTIONS_LOADED],
                                                                             [action.payload[con.TRANSACTION_TYPE]] : action.payload[con.VALUE]}}}
            break
        
        case types.SET_LOADING_SURROGATE_PARAMETER:
            // Action that sets a specific transaction loading parameter for the surrogate user 

            state[APPLICATION_PARAMETERS][con.SURROGATE_TRANSACTIONS_LOADED] = {...state[APPLICATION_PARAMETERS][con.SURROGATE_TRANSACTIONS_LOADED],
                [action.payload[con.TRANSACTION_TYPE]] : {[con.STATUS] : action.payload[con.VALUE]}}


            state = {...state,
                    [APPLICATION_PARAMETERS] : {...state[APPLICATION_PARAMETERS],
                                                [con.SURROGATE_TRANSACTIONS_LOADED] : {...state[APPLICATION_PARAMETERS][con.SURROGATE_TRANSACTIONS_LOADED]},
                                                [con.ALL_SURROGATE_TRANSACTIONS_LOADED] : checkIfAllTransactionsLoaded(state[APPLICATION_PARAMETERS][con.SURROGATE_TRANSACTIONS_LOADED])}}   

        break

        case types.SET_INSERTED_PARAMETER:
            // Actions  that sets the status of a transactions inserted parameter
            
            state = {...state,
                    [APPLICATION_PARAMETERS] : {...state[APPLICATION_PARAMETERS],
                                            [con.TRANSACTIONS_INSERTED] : {...state[APPLICATION_PARAMETERS][con.TRANSACTIONS_INSERTED],
                                                                           [action.payload[con.TRANSACTION_TYPE]] : action.payload[con.VALUE]}}}
                break

        case types.NEXT_PAGE:
            // Action that navigates to the next page in the transaction visualization page (inputs)

            transactionType = action.payload
            selectedReducer = getTransactionReducer(transactionType)

            newPage = state[APPLICATION_PARAMETERS][con.CURRENT_TRANSACTION_NAVIGATION_PAGE][transactionType] +1
            newPage = Math.min(newPage, getTotalTransactionPages(state[APPLICATION_PARAMETERS][con.TOTAL_TRANSACTIONS_DISPLAYED][transactionType]) )
            
            state = {...state,
                [APPLICATION_PARAMETERS] : {...state[APPLICATION_PARAMETERS],
                                            [con.CURRENT_TRANSACTION_NAVIGATION_PAGE] : {...state[APPLICATION_PARAMETERS][con.CURRENT_TRANSACTION_NAVIGATION_PAGE],
                                                                       [transactionType] : newPage}}}
            break

        case types.FINAL_PAGE:
            // Action that goes to the final navigation page on the transaction visualization page (inputs)

                transactionType = action.payload
                selectedReducer = getTransactionReducer(transactionType)

                newPage = getTotalTransactionPages(state[APPLICATION_PARAMETERS][con.TOTAL_TRANSACTIONS_DISPLAYED][transactionType])
                
                state = {...state,
                    [APPLICATION_PARAMETERS] : {...state[APPLICATION_PARAMETERS],
                                                [con.CURRENT_TRANSACTION_NAVIGATION_PAGE] : {...state[APPLICATION_PARAMETERS][con.CURRENT_TRANSACTION_NAVIGATION_PAGE],
                                                                           [transactionType] : newPage}}}
                break

        case types.PREVIOUS_PAGE:
            // Action that navigates to the previous page in the transaction visualization page (inputs)

            transactionType = action.payload

            newPage = state[APPLICATION_PARAMETERS][con.CURRENT_TRANSACTION_NAVIGATION_PAGE][transactionType] -1
            newPage = Math.max(newPage, 0)
            
            state = {...state,
                [APPLICATION_PARAMETERS] : {...state[APPLICATION_PARAMETERS],
                                            [con.CURRENT_TRANSACTION_NAVIGATION_PAGE] : {...state[APPLICATION_PARAMETERS][con.CURRENT_TRANSACTION_NAVIGATION_PAGE],
                                                                       [transactionType] : newPage}}}

            break

        case types.FIRST_PAGE:
            // Action that navigates to the first page in the transaction visualization page (inputs)

            transactionType = action.payload
            newPage = 0
            
            state = {...state,
                [APPLICATION_PARAMETERS] : {...state[APPLICATION_PARAMETERS],
                                            [con.CURRENT_TRANSACTION_NAVIGATION_PAGE] : {...state[APPLICATION_PARAMETERS][con.CURRENT_TRANSACTION_NAVIGATION_PAGE],
                                                                        [transactionType] : newPage}}}
            break


        // User Profile
        // ----------------
        // User profile and other user attributes
        case types.REPLACE_USER_PROFILE:
            // Actions tha replaces a user profile form one fetched by the server or passed for surrogate behavior                
            state = {
                ...state,
                [USER_PROFILE] : { ...DEFAULT_USER_PROFILE, ...action.payload}
                }
                break
        
        // User Lines
        // ----------
        // Manipulation of the user lines
        case types.REPLACE_USER_LINES:
            // Action that replaces the user lines (can be by loading or by surrogate user activation)
            selectedLine = null
            if(action.payload.length > 0)
                selectedLine = action.payload[0][con.ID]
                            
            state = {
                ...state,
                [APPLICATION_PARAMETERS] : {...state[APPLICATION_PARAMETERS], [con.SELECTED_LINE] : selectedLine},
                [USER_LINES] : [...action.payload]
                }
                break

        case types.SET_USER_LINE:
            // Action that selects a user line to display

                // Checks for the user lines
                selectedLineIndex = state[USER_LINES].findIndex(line => line[con.ID] === action.payload[con.ID])
                lines = state[USER_LINES]
                lines[selectedLineIndex] = {...state[USER_LINES][selectedLineIndex], ...action.payload}

                state = {
                    ...state,
                    [USER_LINES] : [...lines]

                }

                break

        case types.DELETE_USER_LINE:
            // Action that delete a user line (this action cannot be undone and can only be done by an admin level user)

            // Checks for the user lines            
            lines = state[USER_LINES]
            lines = lines.filter(line => line[con.ID] !== action.payload[con.ID])

            selectedLine = state[APPLICATION_PARAMETERS][con.SELECTED_LINE]
            if(selectedLine === action.payload[con.ID])
                selectedLine = lines[0][con.ID]


            state = {
                ...state,
                [APPLICATION_PARAMETERS] : {...state[APPLICATION_PARAMETERS], [con.SELECTED_LINE] : selectedLine},
                [USER_LINES] : [...lines]
            }

            break
        
        case types.ADD_USER_LINE:
            // Action that adds a user line (this can only be done by an admin level user)

            // Checks for the user lines            
            lines = state[USER_LINES]
            lines.push(action.payload)

            state = {
                ...state,
                [USER_LINES] : [...lines]
            }

            break

        case types.ADD_USER_LINE_COMMENT:
            // Action that adds a comment to a user line line to be displayed in the admin panel summary
            state = {
                ...state,                                              
                    [ADMIN_LINE_COMMENTS] : [...state[ADMIN_LINE_COMMENTS], action.payload]
                }
                            
            break

        case types.SET_ALL_USER_LINE_COMMENTS:
            // Action that sets all the user line comments from the server
            
            state = {
                ...state,                                              
                    [ADMIN_LINE_COMMENTS] : [...action.payload]
                }
                
            
            break

        // Model Parameters
        // ------------------------
        // Manipulation of the model parameters
        case types.REPLACE_MODEL_PARAMETERS:
            // Action thar replaces all the model parameters (usually from the server).
            
            currentParameters = {...state[MODEL_PARAMETERS], ...action.payload}
            currentMarketValues = state[MARKET_VALUES]
            currentAppParameters = state[APPLICATION_PARAMETERS]
                            
            calculateTermInMonths(currentParameters, state, currentAppParameters)
            
            // Scenarios to update
            scenarios = {}
            let mid_band_should_length = currentParameters[con.TERM_PERIODICITY].length + 1
      
            if(!(con.MID_BAND_VALUES in action.payload) ||
                action.payload[con.MID_BAND_VALUES] === null)
                currentParameters[con.MID_BAND_VALUES] = generateBands(currentParameters[con.AVERAGE_FOR_PERCENTAGE],
                                                                        currentParameters[con.TERM_PERIODICITY],
                                                                        currentParameters[con.INITIAL_COVERAGE],
                                                                        currentParameters[con.GRADIENT])
            
            // Adjust if bands length does not match term_periodicity                                                           
            else if(mid_band_should_length < currentParameters[con.MID_BAND_VALUES].length){
                currentParameters[con.MID_BAND_VALUES] = currentParameters[con.MID_BAND_VALUES].slice(0, mid_band_should_length)
            }
            else if(mid_band_should_length > currentParameters[con.MID_BAND_VALUES].length){
                while (currentParameters[con.MID_BAND_VALUES].length < mid_band_should_length) {
                    const last_value = currentParameters[con.MID_BAND_VALUES][currentParameters[con.MID_BAND_VALUES].length - 1];
                    currentParameters[con.MID_BAND_VALUES].push(last_value);
                }
                    
            }                   

                    
            // Var and Percentage Scenario Parameters        
            // Updates Scenarios
            scenarios[con.SCENARIO_VAR_UP] = {...state[SCENARIOS][con.SCENARIO_VAR_UP], [con.GENERATING_FUNCTION] : getGeneratorScenarioFunctionByVolatility(currentParameters[con.MARKET_VOLATILITY], currentParameters[con.ESC_CONFIDENCE_LEVEL], 1)}
            scenarios[con.SCENARIO_VAR_DOWN] = {...state[SCENARIOS][con.SCENARIO_VAR_DOWN], [con.GENERATING_FUNCTION] : getGeneratorScenarioFunctionByVolatility(currentParameters[con.MARKET_VOLATILITY], currentParameters[con.ESC_CONFIDENCE_LEVEL], -1)}
            scenarios[con.SCENARIO_PERCENTAGE] = {...state[SCENARIOS][con.SCENARIO_PERCENTAGE], [con.GENERATING_FUNCTION] : getGeneratorScenarioFunctionByPercentage(currentParameters[con.ESC_PERCENTUAL_CHANGE], currentParameters[con.CLIENT_TYPE])}
                            
            
            // If custom scenario values is null but is active, will replace with Vinco Scenario 
            if(con.SCENARIO_VINCO in state[SCENARIOS] &&
                currentParameters[con.CUSTOM_SCENARIO_ACTIVE] &&
                (currentParameters[con.CUSTOM_SCENARIO_VALUES] === null ||
                    currentParameters[con.CUSTOM_SCENARIO_VALUES] === undefined))
                    currentParameters[con.CUSTOM_SCENARIO_VALUES] = Array(state[MODEL_PARAMETERS][con.TERM_PERIODICITY]+1).fill(1).map((_, i) => state[SCENARIOS][con.SCENARIO_VINCO][con.GENERATING_FUNCTION]( state[APPLICATION_PARAMETERS][con.SPOT], con.DAYS_IN_MONTH*i))
            
            // Adjust the length of the 
            if(currentParameters[con.CUSTOM_SCENARIO_VALUES] !== null &&
                currentParameters[con.CUSTOM_SCENARIO_VALUES] !== undefined &&
                currentParameters[con.CUSTOM_SCENARIO_VALUES].length !== currentParameters[con.TERM_PERIODICITY] + 1)
                    currentParameters[con.CUSTOM_SCENARIO_VALUES] = AdjustMonthlyValuesToTerm(currentParameters[con.CUSTOM_SCENARIO_VALUES], currentParameters[con.TERM_PERIODICITY])

            
            // If the custom forward curve is null but is active, will replace with markets forward curve 
            if(currentParameters[con.CUSTOM_FORWARD_CURVE_ACTIVE] &&
                (currentParameters[con.CUSTOM_FORWARD_CURVE] === null ||
                    currentParameters[con.CUSTOM_FORWARD_CURVE] === undefined))
                    {                        
                        currentParameters[con.CUSTOM_FORWARD_CURVE] = {
                            [con.DAYS] : con.FORWARD_CURVE_DAYS.map( d =>  daysIdToInt(d)),
                            [con.BUY] : con.FORWARD_CURVE_DAYS.map( d =>  currentMarketValues[con.MARKET_FORWARD_CURVE][d] + currentParameters[con.SPREAD]),
                            [con.SELL] : con.FORWARD_CURVE_DAYS.map( d =>  currentMarketValues[con.MARKET_FORWARD_CURVE][d] - currentParameters[con.SPREAD])
                        }
                        
                        
                    
                    }

            // Sets the application Forward curve if custom is active
            if(currentParameters[con.CUSTOM_FORWARD_CURVE_ACTIVE])            
                currentAppParameters[con.FORWARD_CALCULATOR_FORWARD_CURVE] = {...currentParameters[con.CUSTOM_FORWARD_CURVE]}            
                               

            state = {
                ...state,
                [MODEL_PARAMETERS] : {...currentParameters},
                [SCENARIOS] : {...state[SCENARIOS], ...scenarios},
                [APPLICATION_PARAMETERS] : {...currentAppParameters}
                }
                break


                
        case types.SET_MODEL_PARAMETER:
            // Action thar replaces a singe model parameter.
            // If the change in the parameter has effects they should be included here  

            currentParameters = state[MODEL_PARAMETERS]

            currentMarketValues = state[MARKET_VALUES]

            currentAppParameters = state[APPLICATION_PARAMETERS]
            
            // Extracts and Updates
            let id = action.payload[con.ID]
            currentParameters[id] = action.payload[con.VALUE]


            // Scenarios to updates (in case the corresponding parameters changes)
            scenarios = {}
      
            // Checks which parameter was changed and alters the rest to preserve consistency
            switch(id)
            {
                // Changes Term Periodicity
                case con.TERM_PERIODICITY:
                    // Updates the band values and the custom scenario parameters

                    currentParameters[con.MID_BAND_VALUES] = modifyTerm(currentParameters[con.MID_BAND_VALUES],
                                                                        currentParameters[con.TERM_PERIODICITY],
                                                                        currentParameters[con.INITIAL_COVERAGE],
                                                                        currentParameters[con.GRADIENT])
                    
                    if(currentParameters[con.CUSTOM_SCENARIO_VALUES] === null &&
                        currentParameters[con.CUSTOM_SCENARIO_VALUES] === undefined)
                        currentParameters[con.CUSTOM_SCENARIO_VALUES] = Array(state[MODEL_PARAMETERS][con.TERM_PERIODICITY]+1).fill(1).map((_, i) => state[SCENARIOS][con.SCENARIO_VINCO][con.GENERATING_FUNCTION]( state[APPLICATION_PARAMETERS][con.SPOT], con.DAYS_IN_MONTH*i))
                        
                    currentParameters[con.CUSTOM_SCENARIO_VALUES] = AdjustMonthlyValuesToTerm(currentParameters[con.CUSTOM_SCENARIO_VALUES], currentParameters[con.TERM_PERIODICITY])
                    
                    calculateTermInMonths(currentParameters, state, currentAppParameters)
                    break

                case con.PERIODICITY:   
                    calculateTermInMonths(currentParameters, state, currentAppParameters)
                    break

                // Other band parameters
                case con.GRADIENT:
                case con.INITIAL_COVERAGE:
                case con.AVERAGE_FOR_PERCENTAGE:
                case con.AUTOMATIC_BANDS:
                    // Update the band values
                    
                    if(currentParameters[con.AUTOMATIC_BANDS])
                        currentParameters[con.MID_BAND_VALUES] = generateBands(currentParameters[con.AVERAGE_FOR_PERCENTAGE],
                                                                                currentParameters[con.TERM_PERIODICITY],
                                                                                currentParameters[con.INITIAL_COVERAGE],
                                                                                currentParameters[con.GRADIENT])

                    break
                
                
                // Var and Percentage Scenario Parameters
                case con.CLIENT_TYPE:
                    // Updates direction of scenarios and the default selected scenario

                    // Updates Scenarios
                    scenarios[con.SCENARIO_VAR_UP] = {...state[SCENARIOS][con.SCENARIO_VAR_UP], [con.GENERATING_FUNCTION] : getGeneratorScenarioFunctionByVolatility(currentParameters[con.MARKET_VOLATILITY], currentParameters[con.ESC_CONFIDENCE_LEVEL], 1)}
                    scenarios[con.SCENARIO_VAR_DOWN] = {...state[SCENARIOS][con.SCENARIO_VAR_DOWN], [con.GENERATING_FUNCTION] : getGeneratorScenarioFunctionByVolatility(currentParameters[con.MARKET_VOLATILITY], currentParameters[con.ESC_CONFIDENCE_LEVEL], -1)}
                    scenarios[con.SCENARIO_PERCENTAGE] = {...state[SCENARIOS][con.SCENARIO_PERCENTAGE], [con.GENERATING_FUNCTION] : getGeneratorScenarioFunctionByPercentage(currentParameters[con.ESC_PERCENTUAL_CHANGE], currentParameters[con.CLIENT_TYPE])}
                    
                    // If selected scenario is default, switches
                    if(currentParameters[con.CLIENT_TYPE] === con.IMPORTER
                        && currentAppParameters[con.SELECTED_SCENARIO] === con.SCENARIO_VAR_DOWN)
                            currentAppParameters[con.SELECTED_SCENARIO] = con.SCENARIO_VAR_UP
                    else if(currentParameters[con.CLIENT_TYPE] === con.EXPORTER
                        && currentAppParameters[con.SELECTED_SCENARIO] === con.SCENARIO_VAR_UP)
                            currentAppParameters[con.SELECTED_SCENARIO] = con.SCENARIO_VAR_DOWN
                    break

                case con.MARKET_VOLATILITY:
                case con.ESC_CONFIDENCE_LEVEL:
                case con.ESC_PERCENTUAL_CHANGE:
                    // Updates the scenario behavior
                    
                    // Updates Scenarios
                    scenarios[con.SCENARIO_VAR_UP] = {...state[SCENARIOS][con.SCENARIO_VAR_UP], [con.GENERATING_FUNCTION] : getGeneratorScenarioFunctionByVolatility(currentParameters[con.MARKET_VOLATILITY], currentParameters[con.ESC_CONFIDENCE_LEVEL], 1)}
                    scenarios[con.SCENARIO_VAR_DOWN] = {...state[SCENARIOS][con.SCENARIO_VAR_DOWN], [con.GENERATING_FUNCTION] : getGeneratorScenarioFunctionByVolatility(currentParameters[con.MARKET_VOLATILITY], currentParameters[con.ESC_CONFIDENCE_LEVEL], -1)}
                    scenarios[con.SCENARIO_PERCENTAGE] = {...state[SCENARIOS][con.SCENARIO_PERCENTAGE], [con.GENERATING_FUNCTION] : getGeneratorScenarioFunctionByPercentage(currentParameters[con.ESC_PERCENTUAL_CHANGE], currentParameters[con.CLIENT_TYPE])}
                                        
                    break
                case con.CUSTOM_SCENARIO_VALUES:
                    // Updates the generating function of the custom scenario
                        
                    if(currentParameters[con.CUSTOM_SCENARIO_ACTIVE])
                        scenarios[con.SCENARIO_CUSTOM] = {[con.ID] : con.SCENARIO_CUSTOM, 
                                                        [con.NAME]  : con.SCENARIO_CUSTOM_NAME, 
                                                        [con.GENERATING_FUNCTION] : getGeneratorScenarioFunctionByMonthlyValuesReference(currentParameters[con.CUSTOM_SCENARIO_VALUES], false, true)}

                    break
                
                case con.CUSTOM_SCENARIO_ACTIVE:
                    // Updates the value of the custom scenario (assigns th Vinco if custom is missing)

                    
                    if(currentParameters[con.CUSTOM_SCENARIO_ACTIVE])
                    {
                        // If scenario is null, it creates it based on the vinco scenario
                        if(con.SCENARIO_VINCO in state[SCENARIOS] && 
                            (currentParameters[con.CUSTOM_SCENARIO_VALUES] === undefined ||
                            currentParameters[con.CUSTOM_SCENARIO_VALUES] === null))                            
                                currentParameters[con.CUSTOM_SCENARIO_VALUES] = Array(state[MODEL_PARAMETERS][con.TERM_PERIODICITY]+1).fill(1).map((_, i) => state[SCENARIOS][con.SCENARIO_VINCO][con.GENERATING_FUNCTION]( state[APPLICATION_PARAMETERS][con.SPOT], con.DAYS_IN_MONTH*i))
                                                            
                            
                        scenarios[con.SCENARIO_CUSTOM] = {[con.ID] : con.SCENARIO_CUSTOM, 
                                                            [con.NAME]  : con.SCENARIO_CUSTOM_NAME, 
                                                            [con.GENERATING_FUNCTION] : getGeneratorScenarioFunctionByMonthlyValuesReference(currentParameters[con.CUSTOM_SCENARIO_VALUES], false, true)}
                                                
                    }
                    else
                    {
                        if(con.SCENARIO_CUSTOM in state[SCENARIOS])
                            state[SCENARIOS] = deleteAttributeFromObject(state[SCENARIOS], con.SCENARIO_CUSTOM)
                    }
                        
                    
                    break

                case con.CUSTOM_FORWARD_CURVE_ACTIVE:


                    // If the custom forward curve is null it will replace with markets forward curve 
                    if(currentParameters[con.CUSTOM_FORWARD_CURVE_ACTIVE] &&
                        (currentParameters[con.CUSTOM_FORWARD_CURVE] === null ||
                            currentParameters[con.CUSTOM_FORWARD_CURVE] === undefined))
                            {
                                currentParameters[con.CUSTOM_FORWARD_CURVE] = {
                                    [con.DAYS] : con.FORWARD_CURVE_DAYS.map( d =>  daysIdToInt(d)),
                                    [con.BUY] : con.FORWARD_CURVE_DAYS.map( d =>  currentMarketValues[con.MARKET_FORWARD_CURVE][d] + currentParameters[con.SPREAD]),
                                    [con.SELL] : con.FORWARD_CURVE_DAYS.map( d =>  currentMarketValues[con.MARKET_FORWARD_CURVE][d] - currentParameters[con.SPREAD])}

                            }

                    // Sets the application Parameter
                    if(currentParameters[con.CUSTOM_FORWARD_CURVE_ACTIVE])            
                        currentAppParameters[con.FORWARD_CALCULATOR_FORWARD_CURVE] = {...currentParameters[con.CUSTOM_FORWARD_CURVE]}
                    else
                    {
                        currentAppParameters[con.FORWARD_CALCULATOR_FORWARD_CURVE] = {
                            [con.DAYS] : con.FORWARD_CURVE_DAYS.map( d =>  daysIdToInt(d)),
                            [con.BUY] : con.FORWARD_CURVE_DAYS.map( d =>  currentMarketValues[con.MARKET_FORWARD_CURVE][d] + currentParameters[con.SPREAD]),
                            [con.SELL] : con.FORWARD_CURVE_DAYS.map( d =>  currentMarketValues[con.MARKET_FORWARD_CURVE][d] - currentParameters[con.SPREAD])}
                    }

                    break

                case con.CUSTOM_FORWARD_CURVE:
                    // Updates the forward curve if active
                    if(currentParameters[con.CUSTOM_FORWARD_CURVE_ACTIVE])            
                        currentAppParameters[con.FORWARD_CALCULATOR_FORWARD_CURVE] = {...currentParameters[con.CUSTOM_FORWARD_CURVE]}
                    break

                default:
                    break

            }       

                    
            state = {
                ...state,
                [MODEL_PARAMETERS] : {...currentParameters},
                [SCENARIOS] : {...state[SCENARIOS], ...scenarios},
                [APPLICATION_PARAMETERS] : {...currentAppParameters}
                }
                break

        // User Line Application Parameters
        // -------------------------------
        // Manipulation of the Line Application Parameters (Parameters associated que the user line)
        case types.REPLACE_USER_LINE_APPLICATION_PARAMETER:
            // Action that replaces all user lin parameters

            state = {
                ...state,
                [USER_LINE_APPLICATION_PARAMETERS] :{...state[USER_LINE_APPLICATION_PARAMETERS], ...action.payload}}
                

            break

        case types.SET_USER_LINE_APPLICATION_PARAMETER:
            // Action that sets a specific user line parameter

            state = {
                ...state,
                [USER_LINE_APPLICATION_PARAMETERS] :{...state[USER_LINE_APPLICATION_PARAMETERS], [action.payload[con.ID]] : action.payload[con.VALUE] }}                

            break

        // Transactions
        // -----------------
        // Transaction manipulation. Most of the action are generic and the transaction type (Exposure, Coverage FWD, Coverage Spot, etc..)
        // Is expected in the payload
        case types.RESET_ALL_TRANSACTIONS:
            // Action that resets all transactions to their default (no transactions) value

            state = {...state,
                    [APPLICATION_PARAMETERS] : {...state[[APPLICATION_PARAMETERS]],
                                                    [con.TRANSACTIONS_LOADED] : {[con.EXPOSURES] : {[con.STATUS] : con.NOT_STARTED},
                                                                                            [con.COVERAGES_FWD] : {[con.STATUS] : con.NOT_STARTED},
                                                                                            [con.COVERAGES_SPOT] : {[con.STATUS] : con.NOT_STARTED},
                                                                                            [con.COVERAGES_OPTION] : {[con.STATUS] : con.NOT_STARTED},
                                                                                            [con.ACCOUNTS] : {[con.STATUS] : con.NOT_STARTED},
                                                                                            [con.MARKET_ALERTS] : {[con.STATUS] : con.NOT_STARTED},
                                                                                            
                                                                                        },
                                                    [con.ALL_TRANSACTIONS_LOADED] : false},
                    [ACCOUNTS] : {},
                    [EXPOS] : {},
                    [FWD_COVERAGES] : {},
                    [SPOT_COVERAGES] : {},
                    [OPTION_COVERAGES] : {},
                    [MARKET_ALERTS] : {},
                    [ACCOUNTS_ANN] : {},
                    [EXPOS_ANN] : {},
                    [FWD_COVERAGES_ANN] : {},
                    [SPOT_COVERAGES_ANN] : {},
                    [OPTION_COVERAGES_ANN] : {}
                }
                break 

        case types.SET_TRANSACTIONS_FROM_SERVER:
            // Action that sets the transactions from the server

            transactionType = action.payload[con.TRANSACTION_TYPE]
            selectedReducer = getTransactionReducer(transactionType)

            // If transactions can expire. Checks for state
            if(con.EXPIRING_TRANSACTION_TYPES.includes(transactionType))
            {
                Object.values(action.payload[con.VALUE]).forEach((newOb) => {
                    // Checks for state
                    if(newOb[con.STATE] !== con.PREPAYED)
                        newOb[con.STATE] = (parseDate(newOb[con.EXPIRATION_DATE]) > state[MODEL_PARAMETERS][con.CURRENT_DATE]) ? con.ACTIVE : con.EXPIRED           
                })
            }
            
            // Sets state                
            state = {...state, 
                [APPLICATION_PARAMETERS] : {...state[APPLICATION_PARAMETERS],
                                            [con.TRANSACTIONS_LOADED] : {...state[APPLICATION_PARAMETERS][con.TRANSACTIONS_LOADED],
                                                                        [transactionType] : {[con.STATUS] : con.OK}},
                                            [con.HAS_TRANSACTIONS] : (transactionType === con.EXPOSURES) ? Object.keys(action.payload[con.VALUE]) > 0 : state[APPLICATION_PARAMETERS][con.HAS_TRANSACTIONS]},

                [selectedReducer] : action.payload[con.VALUE]}
            
            break


        case types.REPLACE_TRANSACTIONS:
            // Action that replaces all the transactions of a single type. This is usually generated by an excel upload of transactions 

            transactionType = action.payload[con.TRANSACTION_TYPE]
            selectedReducer = getTransactionReducer(transactionType)

            // If transactions can expire. Checks for state
            if(con.EXPIRING_TRANSACTION_TYPES.includes(transactionType))
            {
                Object.values(action.payload[con.VALUE]).forEach((newOb) => {
                    // Checks for state
                    if(newOb[con.STATE] !== con.PREPAYED)
                        newOb[con.STATE] = (parseDate(newOb[con.EXPIRATION_DATE]) > state[MODEL_PARAMETERS][con.CURRENT_DATE]) ? con.ACTIVE : con.EXPIRED           
                    
                })

            }

            // Sets the state
            state = {...state, 
                [APPLICATION_PARAMETERS] : {...state[APPLICATION_PARAMETERS],
                                            [con.HAS_TRANSACTIONS] : (transactionType === con.EXPOSURES) ? Object.keys(action.payload[con.VALUE]) > 0 : state[APPLICATION_PARAMETERS][con.HAS_TRANSACTIONS]},
                [getTransactionAnnotationReducer(transactionType)] : {},
                [selectedReducer] : action.payload[con.VALUE]}
            
            break


        case types.ADD_TRANSACTION:
            // Action that adds a single transaction 

            // Accounts have no add method
            if(action.payload[con.TRANSACTION_TYPE] === con.ACCOUNTS)
                break

            transactionType = action.payload[con.TRANSACTION_TYPE]
            selectedReducer = getTransactionReducer(transactionType)
            

            // Sets the values
            newOb = action.payload[con.TRANSACTION]

            // If transactions can expire. Checks for state
            if(con.EXPIRING_TRANSACTION_TYPES.includes(transactionType))
            {
                if(newOb[con.STATE] !== con.PREPAYED)
                    newOb[con.STATE] = (parseDate(newOb[con.EXPIRATION_DATE]) > state[MODEL_PARAMETERS][con.CURRENT_DATE]) ? con.ACTIVE : con.EXPIRED    
            }
            


            // Is Spot Coverage. Expects compensation account and annotation
            if(transactionType === con.COVERAGES_SPOT && action.payload[con.ADD_SPOT_COVERAGE_TO_COMPENSATION_ACCOUNT] === true)
            {
                // Compensation Account
                state[ACCOUNTS][action.payload[con.COMPENSATION_ACCOUNT][con.ID]] = action.payload[con.COMPENSATION_ACCOUNT]
                
                // Annotation
                addAnnotation(state, con.ACCOUNTS, action.payload[con.COMPENSATION_ACCOUNT][con.ID], action.payload[con.ANNOTATION])

            }

            // Sets the state
            state[selectedReducer][newOb[con.ID]] = newOb
            
            state = {...state,
                [APPLICATION_PARAMETERS] : {...state[APPLICATION_PARAMETERS],
                                            [con.HAS_TRANSACTIONS] : (transactionType === con.EXPOSURES) ? true : state[APPLICATION_PARAMETERS][con.HAS_TRANSACTIONS]},
                [ACCOUNTS] : {...state[ACCOUNTS]},
                [ACCOUNTS_ANN] : {...state[ACCOUNTS_ANN]},
                [selectedReducer] : {...state[selectedReducer]}
            }
            
            break

        case types.SET_TRANSACTION:
            // Action that sets (updates) a single transaction. 
            // Must include annotation
            
            transactionType = action.payload[con.TRANSACTION_TYPE]
            selectedReducer = getTransactionReducer(transactionType)

            // Extracts the values
            newOb = action.payload[con.TRANSACTION]
            annotation = action.payload[con.ANNOTATION]

            // If transactions can expire. Checks for state
            if(con.EXPIRING_TRANSACTION_TYPES.includes(transactionType))
            {
                if(newOb[con.STATE] !== con.PREPAYED)
                    newOb[con.STATE] = (parseDate(newOb[con.EXPIRATION_DATE]) > state[MODEL_PARAMETERS][con.CURRENT_DATE]) ? con.ACTIVE : con.EXPIRED    
            }
            
            addAnnotation(state, transactionType, newOb[con.ID], annotation)

            state = {...state,
                    [selectedReducer] : {...state[selectedReducer], [newOb[con.ID]] : newOb}
                    }
            break

        case types.DELETE_TRANSACTION:
            // Action that deletes a single transaction. This cannot be undone

            // Accounts have no delete method
            if(action.payload[con.TRANSACTION_TYPE] === con.ACCOUNTS)
                break

            transactionType = action.payload[con.TRANSACTION_TYPE]
            selectedReducer = getTransactionReducer(transactionType)
            
            // Sets the state
            state[selectedReducer] = deleteAttributeFromObject(state[selectedReducer], action.payload[con.TRANSACTION_ID])
            
            state[getTransactionAnnotationReducer(transactionType)] = deleteAttributeFromObject(state[getTransactionAnnotationReducer(transactionType)], action.payload[con.TRANSACTION_ID])
            state[APPLICATION_PARAMETERS][con.HAS_TRANSACTIONS] = (transactionType === con.EXPOSURES) ? Object.keys(state[selectedReducer]).length > 0 : state[APPLICATION_PARAMETERS][con.HAS_TRANSACTIONS]
            
            state = {...state,
                    [APPLICATION_PARAMETERS] : {...state[APPLICATION_PARAMETERS],                                                                                                
                                                [con.HAS_TRANSACTIONS] : (transactionType === con.EXPOSURES) ? Object.keys(state[selectedReducer]).length > 0 : state[APPLICATION_PARAMETERS][con.HAS_TRANSACTIONS]},
                    [selectedReducer] : {...state[selectedReducer]},
                    [getTransactionAnnotationReducer(transactionType)] : state[getTransactionAnnotationReducer(transactionType)]}
            break 
            
        case types.ADD_TRANSACTIONS_BULK:
            // Action that adds several transactions (bulk) of a single type.

            transactionType = action.payload[con.TRANSACTION_TYPE]
            selectedReducer = getTransactionReducer(transactionType)

            // If transactions can expire. Checks for state
            if(con.EXPIRING_TRANSACTION_TYPES.includes(transactionType))
            {
                Object.values(action.payload[con.VALUE]).forEach((newOb) => {
                    // Checks for state
                    if(newOb[con.STATE] !== con.PREPAYED)
                        newOb[con.STATE] = (parseDate(newOb[con.EXPIRATION_DATE]) > state[MODEL_PARAMETERS][con.CURRENT_DATE]) ? con.ACTIVE : con.EXPIRED           
                    
                })

            }

            // Sets the state
            state[APPLICATION_PARAMETERS][con.HAS_TRANSACTIONS] = (transactionType === con.EXPOSURES) ? Object.keys(action.payload[con.VALUE]) > 0 : state[APPLICATION_PARAMETERS][con.HAS_TRANSACTIONS]
            state[selectedReducer] = {...state[selectedReducer], ...action.payload[con.VALUE]}
  

            state = {...state}
            break

        // Exposures
        // ---------------------
        // Specific Actions for the Exposure type of transactions     
        
        case types.PREPAY_EXPOSURE:
            // Action that prepays an  exposure
            
            // Should receive all the elements to update
            // The exposure, the spotCoverage and the compensation account (missing if there is no need to update)
            state[EXPOS] = {...state[EXPOS], [action.payload[con.TRANSACTION][con.ID]] : {...action.payload[con.TRANSACTION]}}

            if(con.SPOT_COVERAGE in action.payload)
                state[SPOT_COVERAGES] = {...state[SPOT_COVERAGES], [action.payload[con.SPOT_COVERAGE][con.ID]] : action.payload[con.SPOT_COVERAGE]}
            
            if(con.COMPENSATION_ACCOUNT in action.payload)
            {
                state[ACCOUNTS] = {...state[ACCOUNTS], [action.payload[con.COMPENSATION_ACCOUNT][con.ID]] : action.payload[con.COMPENSATION_ACCOUNT]}
                addAnnotation(state, con.ACCOUNTS, action.payload[con.COMPENSATION_ACCOUNT][con.ID], action.payload[con.ACCOUNT_ANNOTATION])
            }
            if(con.PREPAY_EXPOSURE in action.payload)
            {
                state[EXPOS] = {...state[EXPOS], [action.payload[con.PREPAY_EXPOSURE][con.ID]] : action.payload[con.PREPAY_EXPOSURE]}
                addAnnotation(state, EXPOS, action.payload[con.PREPAY_EXPOSURE][con.ID], action.payload[con.PREPAY_EXPOSURE_ANNOTATION])
            }


            //Adds the exposure annotation
            addAnnotation(state, con.EXPOSURES, action.payload[con.TRANSACTION][con.ID], action.payload[con.ANNOTATION])

            
            state = {
                ...state,
                [APPLICATION_PARAMETERS] : {...state[APPLICATION_PARAMETERS], [con.INTERNAL_TRANSACTION_CHANGE] : state[APPLICATION_PARAMETERS][con.INTERNAL_TRANSACTION_CHANGE] + 1},
                [EXPOS] : {...state[EXPOS]},
                [ACCOUNTS] : {...state[ACCOUNTS]},
                [SPOT_COVERAGES] : {...state[SPOT_COVERAGES]}
            }
            break    


        // Annotations
        // ------------
        // Manipulation of the different annotation (comments) done to a transaction
        case types.ADD_ANNOTATION:
            // Action that adds a single transaction

            transactionType = action.payload[con.TRANSACTION_TYPE]
            addAnnotation(state, transactionType, action.payload[con.ID], action.payload[con.VALUE])

            state = {
                ...state,
                [selectedReducer] : { ...state[selectedReducer], [action.payload[con.ID]] : selectedAnnotations},                
                }
            break

        case types.SET_ANNOTATIONS_FROM_SERVER:
            // Action that sets the annotation from the values obtained in the server

            selectedReducer = getTransactionAnnotationReducer(action.payload[con.TRANSACTION_TYPE])

            state = {
                ...state,
                [selectedReducer] : { ...action.payload[con.VALUE]},                
                }
            break
            
        


        // Scenarios
        // -------------------------
        // Manipulation of scenarios
        case types.REPLACE_SCENARIOS:
            // Action that replaces all scenarios from server
            
            state = {
                ...state,
                [SCENARIOS] : { ...state[SCENARIOS], ...action.payload},
                }
            break
                
        case types.SET_SCENARIO:
            // Action that sets a single scenario
            
            custom_scenario_values = state[MODEL_PARAMETERS][con.CUSTOM_SCENARIO_VALUES]
            // Checks if scenario is Vinco's and updates custom scenario values
            if(action.payload[con.ID] === con.SCENARIO_VINCO &&
                (state[MODEL_PARAMETERS][con.CUSTOM_SCENARIO_VALUES] === null ||
                    state[MODEL_PARAMETERS][con.CUSTOM_SCENARIO_VALUES] === undefined))                    
                        custom_scenario_values = Array(state[MODEL_PARAMETERS][con.TERM_PERIODICITY]+1).fill(1).map((_, i) => action.payload[con.VALUE][con.GENERATING_FUNCTION]( state[APPLICATION_PARAMETERS][con.SPOT], con.DAYS_IN_MONTH*i))
                            
            state = {
                ...state,
                [SCENARIOS] : {...state[SCENARIOS], [action.payload[con.ID]] : action.payload[con.VALUE]},
                [MODEL_PARAMETERS] : {...state[MODEL_PARAMETERS], [con.CUSTOM_SCENARIO_VALUES] : custom_scenario_values}
                }
            break

     

        // Errors
        // ------
        // Error manipulation
        case types.PUSH_ERROR:
            // Action that pushes an Error to the stack
            if(state[ERRORS][con.LATEST_ERROR] !== null)
                state[ERRORS][con.SESSION_ERRORS].push()
            
            state = {
                ...state,
                    [ERRORS] : {[con.LATEST_ERROR]: action.payload, [con.SESSION_ERRORS] : state[ERRORS][con.SESSION_ERRORS]}
                }
            break   

        // All Surrogate transactions
        // --------------------------
        case types.SET_ALL_SURROGATE_USER_TRANSACTIONS:
            // Action that sets all the surrogate transactions of a single type from the server 
            transactionType = action.payload[con.TRANSACTION_TYPE]
            transactionArray = action.payload[con.VALUE]

            // TODO: Make switch
            if(transactionType === MODEL_PARAMETERS)
            {
                transactionArray.forEach(trans =>{
                    state[SURROGATE_USERS_TRANSACTIONS][trans[con.USER]][trans[con.USER_LINE]][transactionType] = trans
                })
            }
            else if(transactionType === con.COMMENTS)
            {
                transactionArray.forEach(comm =>{
                    state[SURROGATE_USERS_TRANSACTIONS][comm[con.USER]][comm[con.USER_LINE]][transactionType].push(comm)
                })
            }
            else
            {
                transactionArray.forEach(trans =>{
                    state[SURROGATE_USERS_TRANSACTIONS][trans[con.USER]][trans[con.USER_LINE]][transactionType][trans[con.ID]] = trans
                })
            }


            state[APPLICATION_PARAMETERS][con.SURROGATE_TRANSACTIONS_LOADED] = {...state[APPLICATION_PARAMETERS][con.SURROGATE_TRANSACTIONS_LOADED],
                                                            [transactionType] : {[con.STATUS] : con.OK}}


            state = {
                ...state,
                    [APPLICATION_PARAMETERS] : {...state[APPLICATION_PARAMETERS],
                                                [con.ALL_SURROGATE_TRANSACTIONS_LOADED] : checkIfAllTransactionsLoaded(state[APPLICATION_PARAMETERS][con.SURROGATE_TRANSACTIONS_LOADED])},                                                
                    [SURROGATE_USERS_TRANSACTIONS] : {...state[SURROGATE_USERS_TRANSACTIONS]}
                }

            break;
        
        // Advisors
        // --------
        // Advisor Manipulation. Only admin level users can manipulate advisors
        case types.SET_ALL_ADVISORS:
            // Action that sets all the advisors from the server

            state = {
                ...state,
                    [ADVISORS] : [...action.payload]
                }
            
            break

        case types.ADD_ADVISOR:
            // Action that adds an advisor

            advisors = state[USER_PROFILE][con.ADVISORS] 
            if(!advisors.includes(parseInt(action.payload)))
                advisors.push(parseInt(action.payload))
            
            state = {
                ...state,
                    [USER_PROFILE] : {...state[USER_PROFILE], [con.ADVISORS] : [...advisors]}
                }
            break

        case types.DELETE_ADVISOR:
            // Action that deletes a single advisor

            advisors = state[USER_PROFILE][con.ADVISORS]  

            advisors = advisors.filter( advisorId => advisorId !== action.payload)

            // Checks if it is the main advisor
            mainAdvisorId = state[USER_PROFILE][con.MAIN_ADVISOR]  
            if(mainAdvisorId === action.payload)
                mainAdvisorId = null
            
            state = {
                ...state,
                    [USER_PROFILE] : {...state[USER_PROFILE], [con.ADVISORS] : [...advisors], [con.MAIN_ADVISOR] : mainAdvisorId}
                }
            break
        case types.SET_MAIN_ADVISOR:
            // Action that sets the main advisor

            
            state = {
                ...state,
                    [USER_PROFILE] : {...state[USER_PROFILE], [con.MAIN_ADVISOR] : action.payload}
                }
            break

        // Market Values
        // ----------------
        // Manipulation of the market values (parameters)
        case types.SET_MARKET_VALUE:
            // Action that sets a single market Value. Any effect on changing a market value should be changed here 
            
            // Extracts the application parameters
            currentAppParameters = state[APPLICATION_PARAMETERS]

            // Extracts model parameters
            currentModelParameters = state[MODEL_PARAMETERS]
            
            // And the current market values with he update
            currentMarketValues = state[MARKET_VALUES]
            currentMarketValues[action.payload[con.ID]] = action.payload[con.VALUE]

            switch (action.payload[con.ID]) {
                // Market Assets
                case con.MARKET_ASSETS:
                    // Updates the selected Market Asset
                    
                    let cop =  Object.values(action.payload[con.VALUE]).find((ass) => ass[con.VINCO_CODE] === con.COP_ASSET[con.VINCO_CODE])
                    currentAppParameters[con.SELECTED_MARKET_ASSET] = cop === undefined ? currentAppParameters[con.SELECTED_MARKET_ASSET] : cop[con.ID]

                    break;

                // Forward Curve                
                case con.MARKET_FORWARD_CURVE:
                    // Updates custom forward curves

                    // If custom not active sets the calculator's forward curve to the markets
                    if(!state[MODEL_PARAMETERS][con.CUSTOM_FORWARD_CURVE_ACTIVE])
                    {
                        currentAppParameters[con.FORWARD_CALCULATOR_FORWARD_CURVE] = {
                            [con.DAYS] : con.FORWARD_CURVE_DAYS.map(daysIdToInt),
                            [con.BUY] : con.FORWARD_CURVE_DAYS.map((col) => action.payload[con.VALUE][col] + currentModelParameters[con.SPREAD]),
                            [con.SELL] : con.FORWARD_CURVE_DAYS.map((col) => action.payload[con.VALUE][col] - currentModelParameters[con.SPREAD])
                        }
                    }                    
                    break;

                case con.HISTORIC_DOLLAR_INFO:
                    // Updates the reference TRM

                    
                    if(action.payload[con.VALUE][con.DATES].length > 0)
                    {
                        // Checks if referent to FWD
                        index = binaryIndexSearch(currentAppParameters[con.FORWARD_ASSESSMENT_REFERENCE_DATE],
                                                  action.payload[con.VALUE][con.DATES],
                                                  false)
                        
                        currentAppParameters[con.FORWARD_ASSESSMENT_REFERENCE_TRM] = action.payload[con.VALUE][con.TRM][index]
                        currentAppParameters[con.FORWARD_ASSESSMENT_REFERENCE_NEXT_DAY_TRM] = 
                        action.payload[con.VALUE][con.TRM][index + 1] ?? 
                        action.payload[con.VALUE][con.TRM][index];

                        currentAppParameters[con.FORWARD_ASSESSMENT_REFERENCE_RATE_CLOSE] = action.payload[con.VALUE][con.CLOSE][index]

                        // Checks if referent to OPTION
                        index = binaryIndexSearch(currentAppParameters[con.OPTION_ASSESSMENT_REFERENCE_DATE],
                            action.payload[con.VALUE][con.DATES],
                            false)
                          
                          currentAppParameters[con.OPTION_ASSESSMENT_REFERENCE_TRM] = action.payload[con.VALUE][con.TRM][index]
                          currentAppParameters[con.OPTION_ASSESSMENT_REFERENCE_NEXT_DAY_TRM] = 
                          action.payload[con.VALUE][con.TRM][index + 1] ?? 
                          action.payload[con.VALUE][con.TRM][index];
  
                          currentAppParameters[con.OPTION_ASSESSMENT_REFERENCE_RATE_CLOSE] = action.payload[con.VALUE][con.CLOSE][index]

                    }

                    break;

                case con.ALL_IBR:
                case con.ALL_FORWARD_CURVES:
                case con.ALL_SOFR_CURVES:

                    // Sorts all the IBR curves and Forward Curves by date
                    
                    //Sorts
                    temp = action.payload[con.VALUE]
                    temp.sort(function(a, b){return parseDate(a[con.DATE]) - parseDate(b[con.DATE])});
                    
                    // Creates Object
                    currentMarketValues[action.payload[con.ID]] = {
                        [con.DATES] : temp.map(ob => parseDate(ob[con.DATE])),
                        [con.VALUES] : temp
                    }  
                    
                    break
            
                default:
                    break;
            }
            
            
            state = {
                ...state,
                    [MARKET_VALUES] : {...currentMarketValues},
                    [APPLICATION_PARAMETERS] : {...currentAppParameters},
                    [MODEL_PARAMETERS] : {...currentModelParameters}
                }
            break

          
        default:
            break        

    }    

    // Final manipulations
    // Cleanup scripts
    return({...state, [APPLICATION_PARAMETERS] : {...state[APPLICATION_PARAMETERS], 
                                                    [con.APP_READY] : isAppReady(state),
                                                    [con.ALL_TRANSACTIONS_LOADED] : Object.values({...state[APPLICATION_PARAMETERS][con.TRANSACTIONS_LOADED]}).reduce((a,val) => a && val[con.STATUS] === con.OK , true)}})


}


/**
 * Method that gets the corresponding transaction reducer
 * @param {string} transactionType - The transaction type 
 * @returns The name of the object inside the store
 */
const getTransactionReducer = (transactionType) =>
{
    // Switches over the type of exposure
    switch (transactionType) {
        case con.ACCOUNTS:
            return(ACCOUNTS)            
        case con.EXPOSURES:
            return(EXPOS)            
        case con.COVERAGES_FWD:
            return(FWD_COVERAGES)            
        case con.COVERAGES_SPOT:
            return(SPOT_COVERAGES)
        case con.COVERAGES_OPTION:
            return(OPTION_COVERAGES)   
        case con.MARKET_ALERTS:
            return(MARKET_ALERTS)             
        default:
            throw new Error(`Transaction type not supported: ${transactionType}`)
    }
}


/**
 * Method that gets the corresponding transaction annotation reducer
 * @param {string} transactionType - The transaction type 
 * @returns The name of the object inside the store
 */
const getTransactionAnnotationReducer = (transactionType) =>
{
    // Switches over the type of exposure
    switch (transactionType) {
        case con.ACCOUNTS:
            return(ACCOUNTS_ANN)            
        case con.EXPOSURES:
            return(EXPOS_ANN)            
        case con.COVERAGES_FWD:
            return(FWD_COVERAGES_ANN)            
        case con.COVERAGES_SPOT:
            return(SPOT_COVERAGES_ANN) 
        case con.COVERAGES_OPTION:
            return(OPTION_COVERAGES_ANN)              
        default:
            throw new Error(`Transaction type not supported: ${transactionType}`)
    }
}


/**
 * Method that adds a single annotation to a specific transaction.
 * @param {object} state - The current state. Will edit the transaction in place
 * @param {string} transactionType - The transaction type 
 * @param {string} transID - The transaction ID 
 * @param {object} annotation - The annotation object 
 */
const addAnnotation = (state, transactionType, transID, annotation) =>
{

    let selectedAnnotations = []
    if(transID in state[getTransactionAnnotationReducer(transactionType)])
        selectedAnnotations = state[getTransactionAnnotationReducer(transactionType)][transID]

    
    // Adds annotation
    selectedAnnotations.push(annotation)

    // Sets the state                        
    state[getTransactionAnnotationReducer(transactionType)][transID] = selectedAnnotations
}


/**
 * Function to determine if the app is ready to start (i.e. go to the main page)
 * @param {object} state - The current state 
 * @returns Boolean indicating if the app is ready or not
 */
const isAppReady = (state) =>
{

    let ready = true
    // User is authenticated
    ready = ready && state[AUTH][con.IS_AUTHENTICATED]

    // Server is Ok
    ready = ready && state[APPLICATION_PARAMETERS][con.SERVER][con.STATUS] === con.OK

    return(ready)


}


/**
 * Method that checks if all the transaction have been loaded to proceed with calculations
 * @param {object} loadDict - Transactions loading dictionary  
 * @returns Boolean indicating if all the transactions have been loaded 
 */
const checkIfAllTransactionsLoaded = (loadDict) =>
{
    return(Object.values(loadDict).reduce((a,val) => a && val[con.STATUS] === con.OK , true))
    
}

/**
 * Method that gets the default parameter configuration for the application parameters when the user logs out
 * @returns Object with the parameter configuration
 */
const getLoggedOutInitialApplicationParameters = () =>
{
    return({...initialState[APPLICATION_PARAMETERS], [con.SERVER] : {[con.STATUS] : con.OK}})
}

/**
 * Updates the term in months value based on the periodicity of the parameters.
 * @param {object} currentParameters - Current parameters object containing periodicity information.
 * @param {object} state - State object containing the model parameters.
 * @param {object} currentAppParameters - Object to be updated with the calculated term in months.
 * @throws {Error} Throws an error if the periodicity is not supported.
 */
function calculateTermInMonths(currentParameters, state, currentAppParameters) {
    switch (currentParameters[con.PERIODICITY]) {
        case con.PERIODICITY_DAY:
            currentAppParameters[con.TERM_IN_MONTHS] = Math.ceil(state[MODEL_PARAMETERS][con.TERM_PERIODICITY] / 30);
            break;
        case con.PERIODICITY_WEEK:
            currentAppParameters[con.TERM_IN_MONTHS] = Math.ceil(state[MODEL_PARAMETERS][con.TERM_PERIODICITY] / 4);
            break;
        case con.PERIODICITY_MONTH:
            currentAppParameters[con.TERM_IN_MONTHS] = state[MODEL_PARAMETERS][con.TERM_PERIODICITY];
            break;
        default:
            throw new Error(`Periodicity not supported: ${currentParameters[con.PERIODICITY]}`);
    }
}

function generate_forward_assessment_filter_fun (currentAppParameters) {
    return(
        (fwd) => {
            let resp = parseDate(fwd[con.OPENING_DATE]) <= currentAppParameters[con.FORWARD_ASSESSMENT_REFERENCE_DATE];
            if(currentAppParameters[con.FORWARD_ASSESSMENT_SHOW_EXPIRED_COVERAGES]){
                resp = resp && (currentAppParameters[con.FORWARD_ASSESSMENT_START_DATE] <= parseDate(fwd[con.EXPIRATION_DATE]))
            } else {
                resp = resp && (parseDate(fwd[con.EXPIRATION_DATE]) > currentAppParameters[con.FORWARD_ASSESSMENT_REFERENCE_DATE])
            }
    
            if(fwd[con.COVERAGE_TYPE] === con.BUY) {
                resp = resp && (currentAppParameters[con.FORWARD_ASSESSMENT_INCLUDE_PURCHASES])
            }
            
            if(fwd[con.COVERAGE_TYPE] === con.SELL) {
                resp = resp && (currentAppParameters[con.FORWARD_ASSESSMENT_INCLUDE_SALES])
            }
            
            return(resp)
        }
    )
}

function generate_option_assessment_filter_fun (currentAppParameters) {
    return(
        (op) => {
            let resp = parseDate(op[con.OPENING_DATE]) <= currentAppParameters[con.OPTION_ASSESSMENT_REFERENCE_DATE];
            if(currentAppParameters[con.OPTION_ASSESSMENT_SHOW_EXPIRED_COVERAGES]){
                resp = resp && (currentAppParameters[con.OPTION_ASSESSMENT_START_DATE] <= parseDate(op[con.EXPIRATION_DATE]))
            } else {
                resp = resp && (parseDate(op[con.EXPIRATION_DATE]) >= currentAppParameters[con.OPTION_ASSESSMENT_REFERENCE_DATE])
            }
    
            if(op[con.COVERAGE_TYPE] === con.BUY) {
                resp = resp && (currentAppParameters[con.OPTION_ASSESSMENT_INCLUDE_PURCHASES])
            }
            
            if(op[con.COVERAGE_TYPE] === con.SELL) {
                resp = resp && (currentAppParameters[con.OPTION_ASSESSMENT_INCLUDE_SALES])
            }

            if(op[con.OPTION_TYPE] === con.CALL) {
                resp = resp && (currentAppParameters[con.OPTION_ASSESSMENT_INCLUDE_CALL])
            }
            
            if(op[con.OPTION_TYPE] === con.PUT) {
                resp = resp && (currentAppParameters[con.OPTION_ASSESSMENT_INCLUDE_PUT])
            }
            
            return(resp)
        }
    )
}