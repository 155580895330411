import React, { useState, useEffect } from 'react';
import { CellText } from '../../templates/tables/Cells';

function SecondLeftRow({ data }) {
    const [paddingSize, setPaddingSize] = useState('10px');  

    
    const adjustStylesBasedOnResolution = () => {
        const width = window.innerWidth;
        const height = window.innerHeight;

        if (width <= 1480 && height <= 720) {
            setPaddingSize('5px'); 
        } else {
            setPaddingSize('10px'); 
        }
    };

    useEffect(() => {
        
        adjustStylesBasedOnResolution();
        
        window.addEventListener('resize', adjustStylesBasedOnResolution);

        
        return () => {
            window.removeEventListener('resize', adjustStylesBasedOnResolution);
        };
    }, []);

    return (
        <div className="flex-fila" style={{ padding: `1px 20px`, textAlign: 'center', whiteSpace: 'pre-line' }}>
            <div className="flex-celda" style={{ padding: paddingSize }}>
                <CellText value={data.cierreAnterior} editing={false} />
            </div>
            <div className="flex-celda" style={{ padding: paddingSize }}>
                <CellText value={data.diffCierreAnterior} editing={false} />
            </div>
            <div className="flex-celda" style={{ padding: paddingSize }}>
                <CellText value={data.trmReal} editing={false} />
            </div>
            <div className="flex-celda" style={{ padding: paddingSize }}>
                <CellText value={data.volatilidadJornada} editing={false} />
            </div>
            <div className="flex-celda" style={{ padding: paddingSize }}>
                <CellText value={`${data.variacionSemanal.toFixed(2)} (${data.variacionSemanalPorcentaje.toFixed(2)}%)`} editing={false} />
            </div>
            <div className="flex-celda" style={{ padding: paddingSize }}>
                <CellText value={data.indicadorAgotamiento} editing={false} />
            </div>
        </div>
    );
}

export default SecondLeftRow;
